<template>
  <div id="app" :class="{ 'container has-boxed-layout': isLayoutBoxed }">
    <NavBar />
    <AsideMenu
      :menu="menu"
      :menu-bottom="menuBottom"
      @menu-click="menuClick"
      :class="{ 'has-secondary': !!menuSecondary }"
    />
    <AsideMenu
      v-if="menuSecondary"
      :menu="menuSecondary"
      :is-secondary="true"
      :label="menuSecondaryLabel"
      :icon="menuSecondaryIcon"
      @menu-click="menuClick"
      @close="menuSecondaryClose"
    />
    <router-view />
    <AsideRight v-if="isManageRole" />
    <FooterBar v-if="isManageRole" />
    <Overlay @overlay-click="overlayClick" />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState } from 'vuex'
import { ShopMenuConfig, InstructorMenuConfig, ManageMenuConfig } from '@/router/menu.js'
import NavBar from '@/components/NavBar.vue'
import AsideMenu from '@/components/AsideMenu.vue'
import FooterBar from '@/components/FooterBar.vue'
import Overlay from '@/components/Overlay.vue'
import AsideRight from '@/components/AsideRight.vue'

import 'tippy.js/dist/tippy.css'

export default {
  name: 'App',
  components: {
    AsideRight,
    Overlay,
    FooterBar,
    AsideMenu,
    NavBar,
  },
  data () {
    return {
      menuSecondary: null,
      menuSecondaryLabel: null,
      menuSecondaryIcon: null
    }
  },
  computed: {
    ...mapState([
      'isOverlayVisible',
      'isLayoutBoxed',
      'isLayoutAsideHidden',
      'isLayoutMobile'
    ]),
    ...mapGetters('storeAuth', ['isAdminRole', 'isLimitedRole', 'isInstructorRole', 'isShopRole', 'isManageRole']),
    menu () {
      if (this.isShopRole) {
        return ShopMenuConfig()
      }
      if (this.isInstructorRole) {
        return InstructorMenuConfig()
      }
      if (this.isManageRole) {
        return ManageMenuConfig(this.isAdminRole, this.isLimitedRole)
      }
      return []
    },
    menuBottom () {
      return [
        // {
        //   action: 'logout',
        //   icon: 'logout',
        //   label: 'Log out',
        //   state: 'info'
        // },
      ]
    },
  },
  mounted () {
    document.documentElement.classList.remove('has-spinner-active')

    if (document.location.search.indexOf('iframe-preview-mode') > -1) {
      this.$store.commit('iframePreviewMode', true)
    }
  },
  methods: {
    menuClick (item) {
      console.log('App.menuClick')
      if (item.menuSecondary) {
        if (this.menuSecondary === item.menuSecondary) {
          this.menuSecondaryClose()
          return
        }
        this.menuSecondary = item.menuSecondary
        this.menuSecondaryLabel = item.menuSecondaryLabel ? item.menuSecondaryLabel : null
        this.menuSecondaryIcon = item.menuSecondaryIcon ? item.menuSecondaryIcon : null

        this.$store.commit('asideActiveForcedKeyToggle', item)
        this.$store.commit('overlayToggle', true)
      } else if (item.action && item.action === 'logout') {
        this.$buefy.toast.open({
          message: 'Log out clicked',
          type: 'is-danger',
          queue: false
        })
      }
    },
    menuSecondaryClose () {
      this.menuSecondary = this.menuSecondaryLabel = this.menuSecondaryIcon = null
      this.$store.commit('asideActiveForcedKeyToggle', null)

      if (!this.isLayoutAsideHidden) {
        this.$store.commit('overlayToggle', false)
      }
    },
    overlayClick () {
      if (this.menuSecondary) {
        this.menuSecondaryClose()
      } else {
        this.$store.dispatch('asideCloseAll')
      }
    }
  },
  watch: {
    isLayoutMobile (newVal) {
      if (newVal) {
        this.$store.commit('layoutBoxedToggle', false)
      }
    },
    $route (to, from) {
      this.menuSecondaryClose()
    }
  },
}
</script>

<style>
@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

.password-binput input {
  font-family: 'password' !important;
}
</style>