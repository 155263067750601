<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    v-slot="{ errors, valid, touched }"
    slim
  >
    <b-field
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0], 'is-success': touched && valid }"
      :message="errors.length ? errors : null"
    >
      <slot />
    </b-field>
  </ValidationProvider>
</template>

<script>

export default {
  props: {
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ''
    },
  },
}
</script>
