var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{staticStyle:{"font-size":"0.9rem"},attrs:{"loading":_vm.isLoading,"paginated":_vm.paginated,"per-page":_vm.perPage,"striped":true,"narrowed":"","default-sort":_vm.defaultSort,"data":_vm.rows,"row-class":function (row, index) { return row.tEvent.rowClass; }}},[_c('b-table-column',{attrs:{"label":"","width":"3em","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.tEvent.isRepeat)?_c('b-icon',{attrs:{"icon":"bookmark-outline"}}):_c('b-icon',{attrs:{"icon":"shield-star-outline"}})]}}])}),_c('b-table-column',{attrs:{"label":"施設","field":"facilitySortVal","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.tEvent.facility)?_c('span',[_vm._v(_vm._s(row.tEvent.facility.label))]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"label":"日程","width":"8em","field":"startDateSortVal","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.tEvent.startDate.format('HH:mm'))+" - "+_vm._s(row.tEvent.endDate.format('HH:mm'))+" ")]}}])}),_c('b-table-column',{attrs:{"label":"時間","width":"4em"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.tEvent.tCheckin)?_c('span',{class:{
        'has-text-danger': row.tEvent.minutes != row.tEvent.tCheckin.minutes,
      }},[_vm._v(_vm._s(row.tEvent.tCheckin.minutes)+"分")]):_c('span',[_vm._v(_vm._s(row.tEvent.minutes)+"分")])]}}])}),_c('b-table-column',{attrs:{"label":"プログラム"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('CheckinProgramLabel',{attrs:{"programId":row.tEvent.programId,"position":"is-left"}})]}}])}),_c('b-table-column',{attrs:{"label":"担当"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [(row.mInstructor)?_c('InstructorLabel',{attrs:{"mInstructor":row.mInstructor,"position":"is-left"}}):(row.tEvent.instructorId)?_c('span',[_vm._v("id:("+_vm._s(row.tEvent.instructorId)+")")]):_c('span',[_vm._v("-")])]}}])}),_c('b-table-column',{attrs:{"width":"9em","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('CheckinStatusTag',{attrs:{"tEvent":row.tEvent}}),(row.tEvent.hasCheckin)?_c('small',{staticClass:"pl-2"},[_vm._v(_vm._s(row.tEvent.checkinAtLabel))]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"width":"2em","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [(row.tEvent.hasCheckin)?_c('span',[(row.tEvent.hasCheckinComment)?_c('b-tooltip',{attrs:{"label":row.tEvent.tCheckin.note,"type":"is-dark","position":"is-left"}},[_c('b-icon',{attrs:{"icon":"comment-outline","type":"is-dark"}})],1):_vm._e()],1):_c('span',[(row.tEvent.note)?_c('b-tooltip',{attrs:{"label":row.tEvent.note,"type":"is-dark","position":"is-left"}},[_c('b-icon',{attrs:{"icon":"comment-outline","type":"is-dark"}})],1):_vm._e()],1)]}}])}),_c('b-table-column',{attrs:{"centered":"","width":"2em"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [(row.mProxy)?_c('b-tooltip',{attrs:{"label":("#" + (row.mProxy.code) + " " + (row.mProxy.name) + "が代行しました"),"type":"is-warning"}},[_c('b-icon',{attrs:{"icon":"bell-alert","type":"is-warning"}})],1):_c('span')]}}])}),_c('b-table-column',{attrs:{"label":"承認","width":"3em","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [(row.tEvent.tCheckin)?_c('b-tooltip',{attrs:{"label":"支払い対象とする","type":"is-dark"}},[_c('b-checkbox',{staticClass:"ml-1",attrs:{"type":"is-success"},on:{"input":function($event){return _vm.willChangeStatus(row.tEvent.tCheckin)}},model:{value:(row.tEvent.tCheckin.confirmFlagChange),callback:function ($$v) {_vm.$set(row.tEvent.tCheckin, "confirmFlagChange", $$v)},expression:"row.tEvent.tCheckin.confirmFlagChange"}})],1):_vm._e()]}}])}),_c('b-table-column',{attrs:{"label":"管理","width":"4em"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('b-button',{attrs:{"type":"is-warning","size":"is-small","icon-left":"square-edit-outline"},on:{"click":function($event){return _vm.willManage(row.tEvent)}}})]}}])}),_c('section',{staticClass:"section",attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[(_vm.isLoading)?[_c('p',[_c('b-icon',{attrs:{"icon":"dots-horizontal","size":"is-large"}})],1),_c('p',[_vm._v("Fetching data...")])]:[_c('p',[_c('b-icon',{attrs:{"icon":"emoticon-sad","size":"is-large"}})],1),_c('p',[_vm._v("Nothing's here…")])]],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }