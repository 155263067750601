import { Enums, TypeEnum } from './enum' // eslint-disable-line no-unused-vars
import { MInstructor } from './typeInstructor'

export class TBonus {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.paymentYmKey = data.payment_ym
    this.area = Enums.PaymentArea.of(data.area)
    this.mInstructor = new MInstructor(data.instructor)
    this.amount = Number(data.amount) || 0
    /** @type {string} */
    this.note = data.note || ''
  }
}

export class BonusEditForm {
  /**
   * @param {string} paymentYmKey
   */
  constructor (paymentYmKey) {
    this.id = 0
    this.paymentYmKey = paymentYmKey
    /** @type {TypeEnum} */
    this.area = null
    /** @type {MInstructor} */
    this.mInstructor = null
    this.amount = ''
    this.note = ''
  }
  /**
   * @param {TBonus} tBonus
   */
  fill (tBonus) {
    this.id = tBonus.id
    this.area = tBonus.area
    this.mInstructor = tBonus.mInstructor
    this.amount = tBonus.amount ? tBonus.amount.toString() : ''
    this.note = tBonus.note
  }
  get params () {
    return {
      id: this.id,
      payment_ym: this.paymentYmKey,
      area: this.area ? this.area.value : null,
      instructor_id: this.mInstructor ? this.mInstructor.id : null,
      amount: this.amount === '' ? null : (Number(this.amount) || 0),
      note: this.note || '',
    }
  }
}

export class TPaymentBonus {
  constructor (data) {
    this.workId = Number(data.work_id) || 0
    this.areaName = data.area_name
    this.instructorId = Number(data.instructor_id) || 0
    this.instructorCode = data.instructor_code
    this.instructorName = data.instructor_name
    this.amount = Number(data.amount) || 0
    /** @type {string} */
    this.note = data.note || ''
  }
}