<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    v-slot="{ errors, valid, touched }"
    slim
  >
    <b-field
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0], 'is-success': touched && valid }"
      :message="errors.length ? errors : null"
    >
      <b-input
        v-model="innerValue"
        v-bind="$attrs"
        :autofocus="autofocus"
      ></b-input>
    </b-field>
  </ValidationProvider>
</template>

<script>

export default {
  props: {
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null
    },
    autofocus: Boolean
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal)
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  mounted () {
    // if (this.focus && this.$refs.binput) {
    //   this.$refs.binput.focus()
    // }
  }
}
</script>
