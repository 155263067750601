<template>
  <Tiles>
    <CardWidget
      class="tile is-child"
      type="is-dark"
      icon="bookmark-outline"
      :number="lessonCount"
      suffix="件"
      label="レッスン数"
    />
    <CardWidget
      class="tile is-child"
      type="is-info"
      icon="check"
      :number="checkinRatio"
      suffix="%"
      label="実施率"
      :tooltip="`${checkinCount} / ${lessonCount}`"
    />
    <CardWidget
      class="tile is-child"
      type="is-success"
      icon="check"
      :number="confirmRatio"
      suffix="%"
      label="承認率"
      :tooltip="`${confirmCount} / ${checkinCount}`"
    />
    <CardWidget
      class="tile is-child"
      type="is-warning"
      icon="bell-alert"
      :number="proxyCount"
      suffix="件"
      label="代行数"
    />
  </Tiles>
</template>

<script>
import { EventTableFilter } from '@/types/typeEvent'
import CardWidget from '../CardWidget.vue'
import Tiles from '../Tiles.vue'
export default {
  components: { Tiles, CardWidget },
  props: {
    tEvents: {
      type: Array,
      default: () => [],
    },
    tableFilter: {
      type: EventTableFilter,
      default: null
    },
  },
  computed: {
    targets () {
      if (this.tableFilter && this.tableFilter.showAll) {
        return this.tEvents
      }
      return this.tEvents.filter(row => row.checkable || row.hasCheckin)
    },
    lessonCount () {
      return this.targets.length
    },
    checkinCount () {
      return this.targets.filter(row => row.hasCheckin).length
    },
    checkinRatio () {
      return this.lessonCount ? Math.floor(this.checkinCount / this.lessonCount * 100) : 0
    },
    confirmCount () {
      return this.targets.filter(row => row.isConfirmed).length
    },
    confirmRatio () {
      return this.checkinCount ? Math.floor(this.confirmCount / this.checkinCount * 100) : 0
    },
    proxyCount () {
      return this.targets.filter(row => row.isProxy).length
    }
  }
}
</script>

<style>
</style>