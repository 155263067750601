
import dayjs from 'dayjs'
import { DateFormat } from '@/config/const'
import { assign, genUniquekey, toHanKana } from '@/util'
import { Enums, TypeEnum } from './enum' // eslint-disable-line no-unused-vars
import { MProgram } from './typeProgram' // eslint-disable-line no-unused-vars

export class MInstructor {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.code = data.code || ''
    this.name = data.name || ''
    this.kana = data.kana || ''
    this.hanKana = toHanKana(this.kana)
    this.displayName = data.display_name || ''
    /** @type {TypeEnum[]} */
    this.feeCategoryExists = data.fee_exists ? data.fee_exists.categories.map(v => Enums.FeeCategory.of(v)) : []
    this.feeSpecialCount = data.fee_exists ? Number(data.fee_exists.special_count) : 0
    this.note = data.note || ''
    this.checkable = data.checkable
    this.active = data.active

    this.feeJr = data.fee_jr ? Number(data.fee_jr) : null
    this.feeYoga = data.fee_yoga ? Number(data.fee_yoga) : null
    this.feeOther = data.fee_other ? Number(data.fee_other) : null
    this.feeAqua = data.fee_aqua ? Number(data.fee_aqua) : null
    this.feeEtc1 = data.fee_etc1 ? Number(data.fee_etc1) : null
    this.feeEtc2 = data.fee_etc2 ? Number(data.fee_etc2) : null
  }

  get activeSortVal () {
    return this.active ? 1 : 0
  }
  get displayLabel () {
    return this.displayName || this.name
  }
  get optionLabel () {
    return `#${this.code} ${this.name}`
  }
}

export class InstructorForm {
  constructor () {
    this.id = null
    this.code = ''
    this.name = ''
    this.kana = ''
    this.displayName = ''
    this.checkable = true
    this.note = ''
    this.active = true
  }
  /**
   * @param {MInstructor} mInstructor
   */
  fill (mInstructor) {
    assign(this, mInstructor)
    return this
  }
  get params () {
    return {
      id: this.id,
      code: this.code,
      name: this.name,
      kana: this.kana,
      display_name: this.displayName || this.name,
      checkable: this.checkable,
      note: this.note,
      active: this.active
    }
  }
}
export class InstructorTableFilter {
  constructor () {
    this.showActive = true
    this.showDeActive = false
    this.showCheckable = true
    this.showUnCheckable = true
    this.text = ''
  }

  /**
   * @param {MInstructor} mInstructor
   */
  test (mInstructor) {
    if (!this.showActive && mInstructor.active) {
      return false
    }
    if (!this.showDeActive && !mInstructor.active) {
      return false
    }
    if (!this.showCheckable && mInstructor.checkable) {
      return false
    }
    if (!this.showUnCheckable && !mInstructor.checkable) {
      return false
    }
    const search = `${mInstructor.code}${mInstructor.name}${mInstructor.displayName}${mInstructor.kana}${mInstructor.note}`
    if (this.text && !search.includes(this.text)) {
      return false
    }
    return true
  }
}

export class TFee {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.category = data.category ? Enums.FeeCategory.of(data.category) : null
    this.programId = data.program_id ? (Number(data.program_id) || 0) : null
    this.amount = Number(data.amount) || 0
    this.startDate = dayjs(data.start_date, DateFormat)
  }
  get isSpecial () {
    return this.category === null && this.programId !== null
  }
}
export class FeeForm {
  constructor () {
    this.key = genUniquekey()
    this.id = 0
    /** @type {TypeEnum} */
    this.category = null
    /** @type {MProgram} */
    this.mProgram = null
    this.amount = ''
    /** @type {dayjs.Dayjs} */
    this.startDate = null
  }

  get startDateSortval () {
    return this.startDate ? this.startDate.format('YYYYMMDD') : ''
  }
  get isSpecial () {
    return this.category === null && this.mProgram !== null
  }
  get isCategory () {
    return this.category !== null
  }
  /**
   * @param {TFee} tFee
   */
  fill (tFee) {
    this.id = tFee.id
    this.amount = tFee.amount
    this.startDate = tFee.startDate
    return this
  }
  /**
   * @param {TypeEnum} category
   */
  setCategory (category) {
    this.category = category
    this.mProgram = null
    return this
  }
  /**
   * @param {MProgram} mProgram
   */
  setProgram (mProgram) {
    this.category = null
    this.mProgram = mProgram
    return this
  }
  reflectCategory ({ amount, startDate }) {
    this.amount = Number(amount) || 0
    this.startDate = dayjs(startDate)
    return this
  }
  reflectProgram ({ mProgram, amount, startDate }) {
    this.mProgram = mProgram
    this.amount = Number(amount) || 0
    this.startDate = dayjs(startDate)
    return this
  }
  get params () {
    return {
      id: this.id,
      category: this.category ? this.category.value : '',
      program_id: this.mProgram ? this.mProgram.id : null,
      amount: Number(this.amount) || 0,
      start_date: this.startDate.format(DateFormat),
    }
  }
}