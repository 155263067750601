import { TimeFormat, WEEKS, DateFormat } from '@/config/const'
import { Enums, TypeEnum } from '@/types/enum' // eslint-disable-line no-unused-vars
import { assign } from '@/util'
import dayjs from 'dayjs'
import { MInstructor } from './typeInstructor' // eslint-disable-line no-unused-vars
import { TLesson } from './typeLesson' // eslint-disable-line no-unused-vars

export class TRepeat {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.periodYm = data.periodYm
    this.area = Enums.Area.of(data.area)

    this.facility = Enums.LessonFacility.of(data.facility)

    this.programId = Number(data.program_id) || 0

    this.weekNo = Number(data.week_no)
    this.startTime = dayjs(`20000101 ${data.start_time}`, `${DateFormat} ${TimeFormat}`)
    this.minutes = Number(data.minutes) || 0
    this.endTime = this.startTime.add(this.minutes, 'minutes')

    this.lessonCount = Number(data.lesson_count) || 0
    /** @type {string} */
    this.note = data.note || ''

    /** @type {boolean} */
    this.active = data.active

    this.instructorIds = data.instructor_ids.map(id => Number(id) || 0)

    /** @type {boolean} */
    this.didCheckined = !!data.checkin_flag
  }

  get defaultSortVal () {
    return `${this.weekNo}-${this.startTime.format(TimeFormat)}-${this.endTime.format(TimeFormat)}`
  }

  get weekLabel () {
    return WEEKS[this.weekNo]
  }

  get facilitySortVal () {
    return this.facility.label
  }
  get startTimeSortVal () {
    return this.startTime.format(TimeFormat)
  }
}

export class RepeatForm {
  constructor () {
    this.id = 0
    /** @type {TypeEnum} */
    this.facility = null

    /** @type {MProgram} */
    this.mProgram = null

    /** @type {MInstructor[]} */
    this.mInstructors = []

    this.weekNo = null
    /** @type {dayjs.Dayjs} */
    this.startTime = null // dayjs().set('hour', 12).set('minute', 0)
    /** @type {number} */
    this.minutes = null
    this.note = ''

    /** @type {RepeatLessonForm[]} */
    this.repeatLessonForms = []
  }

  get mInstructorsAlt () {
    return this.mInstructors
  }
  /** @param { MInstructor } val */
  set mInstructorsAlt (val) {
    this.mInstructors = val
    this.repeatLessonForms.forEach(repeatLessonForm => repeatLessonForm.syncMInstructor(this.mInstructors.map(row => row.id)))
  }

  get mProgramAlt () {
    return this.mProgram
  }
  /** @param {MProgram} val */
  set mProgramAlt (val) {
    this.mProgram = val
    if (val && val.minutes && !this.minutes) {
      this.minutes = val.minutes
    }
  }

  /**
   * @param {TRepeat} tRepeat
   */
  fill (tRepeat) {
    assign(this, tRepeat)
    return this
  }

  /**
   * @param {TLesson[]} tLessons
   */
  fillRepeatLessons (tLessons) {
    this.repeatLessonForms = tLessons.map(tLesson => new RepeatLessonForm(tLesson.targetDate).fill(tLesson))
    return this
  }

  get params () {
    return {
      id: this.id,
      facility: this.facility.value,
      program_id: this.mProgram.id,
      instructor_ids: this.mInstructors.map(row => row.id),
      week_no: this.weekNo,
      start_time: this.startTime.format(TimeFormat),
      minutes: Number(this.minutes) || 0,
      note: this.note || '',
      lessons: this.repeatLessonForms.map(repeatLessonForm => repeatLessonForm.params)
    }
  }
  get calcEndTime () {
    const { startTime, minutes } = this
    if (startTime && minutes) {
      return startTime.add(minutes, 'm')
    }
    return null
  }
}
export class RepeatTableFilter {
  constructor (facilityOptions) {
    this.facilities = facilityOptions || []
    this.weekNo = null
  }
  setFacilities (facilityOptions) {
    this.facilities = facilityOptions
  }

  get weekNoAlt () {
    return this.weekNo
  }
  set weekNoAlt (val) {
    this.weekNo = val ? Number(val) : null
  }
  /**
   * @param {TRepeat} tRepeat
   */
  test (tRepeat) {
    if (!this.facilities.includes(tRepeat.facility)) {
      return false
    }
    if (this.weekNo && Number(this.weekNo) !== tRepeat.weekNo) {
      return false
    }
    return true
  }
}

export class RepeatLessonForm {
  /**
   * @param {dayjs.Dayjs} targetDate
   * */
  constructor (targetDate) {
    /** @type {dayjs.Dayjs} */
    this.targetDate = targetDate
    this.instructorIds = []
    this.active = true
    this.note = ''
    this.noteEditable = false
    this.didCheckined = false
  }

  /** @param {number[]} selectableIds */
  syncMInstructor (selectableIds) {
    this.instructorIds = this.instructorIds.filter(id => selectableIds.includes(id))
  }

  /**
   * @param {TLesson} tLesson
   */
  fill (tLesson) {
    this.instructorIds = tLesson.instructorIds
    this.active = tLesson.active
    this.note = tLesson.note
    this.didCheckined = tLesson.didCheckined
    return this
  }

  get params () {
    return {
      target_date: this.targetDate.format(DateFormat),
      instructor_ids: this.instructorIds,
      note: this.note,
      active: this.active
    }
  }
}

export class RepeatExpandForm {
  /**
   * @param {string} periodYm
   * @param {TypeEnum} area
   * */
  constructor (periodYm, area) {
    this.periodYm = periodYm
    this.area = area
    this.confirmed = false
  }

  get params () {
    return {
      period_ym: this.periodYm,
      area: this.area.value,
      confirmed: this.confirmed
    }
  }
}