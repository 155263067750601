import { ActionContext } from 'vuex' // eslint-disable-line no-unused-vars
import { ApiProgram } from '@/api'
import { MProgram, MProgramGroup, ProgramFilter } from '@/types/typeProgram' // eslint-disable-line no-unused-vars
import { Collection } from '@/util'

const StoreProgramState = {
  dataState: false,
  programFilter: new ProgramFilter(),
  /** @type {MProgram[]} */
  mPrograms: [],
  /** @type {MProgramGroup[]} */
  mProgramGroups: [],
}

export default {
  namespaced: true,
  state: () => StoreProgramState,
  getters: {
    /**
     * @param {StoreProgramState} state
     */
    mPGroupMap (state) {
      return new Collection(state.mProgramGroups).forgeMap('id')
    },
    /**
     * @param {StoreProgramState} state
     */
    findMPGroup: (state, getters) => (id) => {
      return id ? getters.mPGroupMap[id] : null
    },
    /**
     * @param {StoreProgramState} state
     */
    mProgramMap (state) {
      return new Collection(state.mPrograms).forgeMap('id')
    },
    /**
     * @param {StoreProgramState} state
     */
    findMProgram: (state, getters) => (id) => {
      return id ? getters.mProgramMap[id] : null
    },
  },
  mutations: {
    fill (state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
      })
    },
    /**
     * @param {StoreProgramState} state
     */
    resetDataState (state) {
      state.dataState = false
    },
  },
  actions: {
    /**
     * @param {ActionContext<StoreProgramState>} { state, commit }
     */
    async forgeStates ({ state, getters, commit }, force) {
      if (!force && state.dataState) {
        return
      }
      const { mPrograms, mProgramGroups } = await ApiProgram.fetchPrograms()
      commit('fill', { mPrograms, mProgramGroups, dataState: true })
    },
  }
}
