import Vue from 'vue'
import Vuex from 'vuex'
import storeAuth from './storeAuth'
import storeSchedule from './storeSchedule'
import storeCheckin from './storeCheckin'
import storeHoliday from './storeHoliday'
import storePayment from './storePayment'
import storeProgram from './storeProgram'
import storeBilling from './storeBilling'
import { ApiSocketUri, SocketParser } from '@/api'
import { SOCKET_MESSAGE_TYPES } from '@/config/const'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /** @type {WebSocket} */
    webSocket: null,
    wsRetryCount: 0,
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,
    userGroup: null,

    /* NavBar */
    isNavBarVisible: true,
    navBarColor: null,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideExpanded: false,
    isAsideMobileExpanded: false,
    asideActiveForcedKey: null,
    isAsideRightVisible: false,
    isAsideRightActive: false,

    /* Updates */
    hasUpdates: false,

    /* Overlay */
    isOverlayVisible: false,

    /* Layout */
    isLayoutBoxed: false,
    isLayoutAsideHidden: false,
    isLayoutMobile: false,

    /* Iframe */
    isIframePreviewMode: false,

    /* ConfigBox */
    isConfigBoxVisible: true
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* User */
    user (state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
      if (payload.group) {
        state.userGroup = payload.group
      }
    },

    /* Full Page mode */
    fullPage (state, payload) {
      state.isConfigBoxVisible = !payload
      state.isNavBarVisible = !payload
      state.isAsideVisible = !payload
      state.isFooterBarVisible = !payload
      state.isOverlayVisible = false
    },

    /* Aside Desktop Visibility */
    asideVisibilityToggle (state, payload) {
      state.isAsideVisible = payload
    },

    /* Aside Desktop State */
    asideStateToggle (state, payload = null) {
      const htmlAsideClassName = 'has-aside-expanded'
      const isExpand = payload !== null ? payload : !state.isAsideExpanded

      document.documentElement.classList[isExpand ? 'add' : 'remove'](htmlAsideClassName)

      state.isAsideExpanded = isExpand
    },

    /* Aside Mobile State */
    asideMobileStateToggle (state, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideMobileExpanded

      document.documentElement.classList[isShow ? 'add' : 'remove']('has-aside-mobile-expanded')

      state.isAsideVisible = true
      state.isAsideMobileExpanded = isShow
    },

    /* Aside Forced Active Key (when secondary submenu is open) */
    asideActiveForcedKeyToggle (state, payload) {
      state.asideActiveForcedKey = payload && payload.menuSecondaryKey ? payload.menuSecondaryKey : null
    },

    /* Aside Right */
    asideRightToggle (state, payload) {
      state.isAsideRightVisible = payload
      state.isAsideRightActive = payload
      state.hasUpdates = false
    },

    /* Overlay */
    overlayToggle (state, payload = null) {
      const setIsVisible = payload !== null ? payload : !state.isOverlayVisible

      if (!setIsVisible && state.isLayoutAsideHidden && (state.isAsideVisible || state.isAsideRightVisible)) {
        return
      }

      state.isOverlayVisible = setIsVisible

      document.documentElement.classList[setIsVisible ? 'add' : 'remove']('is-clipped')
    },

    /* Layouts */

    layoutBoxedToggle (state, payload = null) {
      const setIsBoxed = payload !== null ? payload : !state.isLayoutBoxed

      state.isLayoutAsideHidden = setIsBoxed
      state.isLayoutBoxed = setIsBoxed
      state.isAsideExpanded = setIsBoxed
      state.isAsideVisible = !setIsBoxed
      state.isAsideRightVisible = false
      state.isAsideRightActive = false

      document.documentElement.classList[setIsBoxed ? 'remove' : 'add']('has-aside-left', 'has-navbar-fixed-top')
      document.documentElement.classList[setIsBoxed ? 'add' : 'remove']('has-boxed-layout', 'has-aside-hidden-layout', 'has-aside-expanded')
    },

    layoutWideToggle (state, payload = null) {
      const setIsWide = payload !== null ? payload : !state.isLayoutBoxed

      state.isLayoutAsideHidden = setIsWide
      state.isAsideExpanded = setIsWide
      state.isAsideVisible = !setIsWide
      state.isAsideRightVisible = !setIsWide

      document.documentElement.classList[setIsWide ? 'remove' : 'add']('has-aside-left')
      document.documentElement.classList[setIsWide ? 'add' : 'remove']('has-aside-hidden-layout', 'has-aside-expanded')
    },

    layoutMobileToggle (state, payload) {
      state.isLayoutMobile = payload
    },

    /* Misc */

    setNavBarColor (state, payload) {
      state.navBarColor = payload
    },

    iframePreviewMode (state, payload) {
      state.isIframePreviewMode = payload
    }
  },
  actions: {
    asideCloseAll ({ commit, state }) {
      commit('asideVisibilityToggle', false)
      commit('asideRightToggle', false)
      commit('overlayToggle', false)
    },
    asideVisibilityToggle ({ commit, state }, payload = null) {
      const setIsVisible = payload !== null ? payload : !state.isAsideVisible

      commit('asideVisibilityToggle', setIsVisible)
      commit('overlayToggle', setIsVisible)
    },
    asideRightToggle ({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideRightVisible

      commit('asideRightToggle', isShow)

      if (state.isLayoutAsideHidden) {
        commit('overlayToggle', isShow)
      }

      if (!state.isLayoutAsideHidden) {
        document.documentElement.classList[isShow ? 'add' : 'remove']('has-aside-right')
      }
    },
    layoutMobileToggle ({ commit, state }) {
      const isMobile = window.innerWidth < 1024

      commit('layoutMobileToggle', isMobile)

      document.documentElement.classList[isMobile && state.isIframePreviewMode ? 'add' : 'remove']('iframe-preview-mode')
    },
    toggleFullPage ({ commit }, payload) {
      commit('layoutBoxedToggle', false)
      commit('fullPage', payload)

      document.documentElement.classList.remove('is-clipped')

      if (payload) {
        document.documentElement.classList.remove('has-aside-left', 'has-navbar-fixed-top')
      }
    },
    forgeSocketStates ({ state, commit, dispatch }) {
      if (!ApiSocketUri) {
        return
      }
      const webSocket = new WebSocket(ApiSocketUri)
      webSocket.onopen = () => {
        console.log('webSocket.onopen')
        commit('basic', { key: 'webSocket', value: webSocket })
        commit('basic', { key: 'wsRetryCount', value: 0 })
      }
      webSocket.onclose = () => {
        console.log('webSocket.onclose')
        const retryCount = state.wsRetryCount + 1
        commit('basic', { key: 'webSocket', value: null })
        commit('basic', { key: 'wsRetryCount', value: retryCount })
        setTimeout(() => {
          dispatch('forgeSocketStates')
        }, (retryCount ** 2) * 1000)
      }
      webSocket.onmessage = (evt) => {
        const jsonData = JSON.parse(evt.data)
        switch (jsonData.type) {
          case SOCKET_MESSAGE_TYPES.UpdateEvent:
            // eslint-disable-next-line no-case-declarations
            const { tEvent } = SocketParser.UpdateEvent(jsonData.body)
            commit('storeCheckin/replaceEventIfExist', { tEvent })
            break
          case SOCKET_MESSAGE_TYPES.ConfirmCheckin:
            // eslint-disable-next-line no-case-declarations
            const { checkinIds, confirmed } = SocketParser.ConfirmCheckin(jsonData.body)
            commit('storeCheckin/setConfirmedIfExist', { checkinIds, confirmed })
            break
          case SOCKET_MESSAGE_TYPES.DeleteEvent:
            // eslint-disable-next-line no-case-declarations
            const { eventIds, lessonId, repeatId } = SocketParser.DeleteEvent(jsonData.body)
            commit('storeCheckin/removeEventIfExist', { eventIds, lessonId, repeatId })
            break
          default:
            console.log(jsonData.type, 'webSocket.onmessage.unknown type')
            console.log(evt.data, 'webSocket.onmessage')
            break
        }
      }
    },
  },
  modules: {
    storeAuth,
    storeSchedule,
    storeCheckin,
    storeHoliday,
    storePayment,
    storeProgram,
    storeBilling,
  }
})
