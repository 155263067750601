<template>
  <article class="media mb-0">
    <div class="media-content">
      <div class="content">
        <p class="media-meta" v-if="mInstructor">
          <b-icon v-if="proxy" icon="bell-alert" type="is-warning" />
          <strong>
            <InstructorLabel :mInstructor="mInstructor" />
          </strong>
          <br />
          <CheckinProgramLabel :programId="tEvent.programId" />
          <br />
          <small class="has-text-grey">{{ tEvent.facility.label }}</small>
          <small class="has-text-grey">
            <b-icon icon="clock-outline" size="is-small" />
            {{ tCheckin.checkinAt.format('HH:mm') }}</small
          >
        </p>
        <small v-if="note">
          {{ note }}
        </small>
      </div>
    </div>
    <div class="media-right has-text-right">
      <div class="blocks">
        <CheckinStatusTag :tEvent="tEvent" />
      </div>
    </div>
  </article>
</template>

<script>
import CheckinStatusTag from './CheckinStatusTag.vue'
import { TEvent } from '@/types/typeEvent'
import { mapGetters } from 'vuex'
import CheckinProgramLabel from './CheckinProgramLabel.vue'
export default {
  components: { CheckinStatusTag, CheckinProgramLabel },
  props: {
    tEvent: TEvent
  },
  computed: {
    ...mapGetters('storeCheckin', ['findMInstructor']),
    tCheckin () {
      return this.tEvent.tCheckin || null
    },
    instructorId () {
      return this.tCheckin.proxyId || this.tEvent.instructorId
    },
    mInstructor () {
      return this.findMInstructor(this.instructorId)
    },
    proxy () {
      return this.tCheckin ? this.findMInstructor(this.tCheckin.proxyId) : null
    },
    note () {
      return this.tCheckin ? this.tCheckin.note : null
    },
  }
}
</script>

<style>
</style>