import { ActionContext } from 'vuex' // eslint-disable-line no-unused-vars
import { Collection } from '@/util'
import { BillingAnswerForm, TBilling } from '@/types/typeBilling' // eslint-disable-line no-unused-vars
import { ApiBilling } from '@/api'

const StoreBillingState = {
  dataState: false,
  /** @type {TBilling[]} */
  tBillings: [],

}

export default {
  namespaced: true,
  state: () => StoreBillingState,
  getters: {
    /**
     * @param {StoreBillingState} state
     */
    findTBilling: (state) => (paymentYmKey) => {
      return paymentYmKey ? state.tBillings.find(row => row.paymentYmKey === paymentYmKey) : null
    }
  },
  mutations: {
    fill (state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
      })
    },
    /**
     * @param {StoreBillingState} state
     * @param {{tBillings: TBilling[]}} {}
     */
    setTBillings (state, { tBillings }) {
      state.tBillings = new Collection(tBillings).sort('paymentYmKey', 'desc').members
    },
    /**
     * @param {StoreBillingState} state
     * @param {{tBilling: TBilling}} {}
     */
    replaceTBilling (state, { tBilling }) {
      state.tBillings = new Collection(state.tBillings).set(tBilling).sort('paymentYmKey', 'desc').members
    },
  },
  actions: {
    /**
     * @param {ActionContext<StoreBillingState>} { state, commit }
     */
    async forgeDatas ({ state, commit }) {
      const { tBillings } = await ApiBilling.fetchBillings()
      commit('setTBillings', { tBillings })
    },
    /**
     * @param {ActionContext<StoreBillingState>} { state, commit }
     * @param {BillingAnswerForm} billingAnswerForm
     */
    async answerBilling ({ state, commit }, billingAnswerForm) {
      console.log('answerBilling')
      const { tBilling } = await ApiBilling.answerBilling(billingAnswerForm)
      commit('replaceTBilling', { tBilling })
    },
  }
}