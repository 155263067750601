import { ActionContext } from 'vuex' // eslint-disable-line no-unused-vars
import { ApiInstructor, ApiPaymentWork, ApiProgram } from '@/api'
import { MInstructor } from '@/types/typeInstructor' // eslint-disable-line no-unused-vars
import { MProgram, MProgramGroup } from '@/types/typeProgram' // eslint-disable-line no-unused-vars
import { Collection } from '@/util'
import { TPaymentWork } from '@/types/typeWork' // eslint-disable-line no-unused-vars
import { PaymentWorkZipForm } from '@/types/typePayment'

const StorePaymentState = {
  dataState: false,
  /** @type {MProgram} */
  mPrograms: [],
  /** @type {MProgramGroup} */
  mProgramGroups: [],
  /** @type {MInstructor[]} */
  mInstructors: [],
  /** @type {TPaymentWork[]} */
  tPaymentWorks: [],
  paymentWorkZipForm: new PaymentWorkZipForm()
}

export default {
  namespaced: true,
  state: () => StorePaymentState,
  getters: {
    /**
     * @param {StorePaymentState} state
     */
    findTPaymentWork: (state) => (paymentYmkey) => {
      return paymentYmkey ? state.tPaymentWorks.find(row => row.paymentYmKey === paymentYmkey) : null
    },
    /**
     * @param {StoreCheckinState} state
     */
    mPGroupMap (state) {
      return new Collection(state.mProgramGroups).forgeMap('id')
    },
    /**
     * @param {StoreCheckinState} state
     */
    findMPGroup: (state, getters) => (id) => {
      return id ? getters.mPGroupMap[id] : null
    },
    /**
     * @param {StorePaymentState} state
     */
    mProgramMap (state) {
      return new Collection(state.mPrograms).forgeMap('id')
    },
    /**
     * @param {StorePaymentState} state
     */
    findMProgram: (state, getters) => (id) => {
      return id ? getters.mProgramMap[id] : null
    },
    /**
     * @param {StorePaymentState} state
     */
    mInstructorsMap (state) {
      return new Collection(state.mInstructors).forgeMap('id')
    },
    /**
     * @param {StorePaymentState} state
     */
    findMInstructor: (state, getters) => (id) => {
      return id ? getters.mInstructorsMap[id] : null
    },
  },
  mutations: {
    fill (state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
      })
    },
    clearData (state) {
      state.dataState = false
    },
    /**
     * @param {StorePaymentState} state
     * @param {{tPaymentWork: TPaymentWork}} {}
     */
    setPaymentWork (state, { tPaymentWork }) {
      state.tPaymentWorks = state.tPaymentWorks.filter(row => row.paymentYmKey !== tPaymentWork.paymentYmKey).concat([tPaymentWork])
    },
  },
  actions: {
    /**
     * @param {ActionContext<StorePaymentState>} { state, commit }
     */
    async forgeStates ({ state, commit }, force) {
      if (!force && state.dataState) {
        return
      }
      const [{ mInstructors }, { mPrograms, mProgramGroups }] = await Promise.all([
        ApiInstructor.fetchInstructors(),
        ApiProgram.fetchPrograms(),
      ])
      commit('fill', { mPrograms, mProgramGroups, mInstructors, dataState: true })
    },
    /**
     * @param {ActionContext<StorePaymentState>} { state, commit }
     */
    async fetchPaymentWorks ({ state, commit }) {
      const { tPaymentWorks } = await ApiPaymentWork.fetchPaymentWorks()
      commit('fill', { tPaymentWorks })
    },
    /**
     * @param {ActionContext<StorePaymentState>} { state, commit }
     */
    async createPaymentWork ({ state, commit }, paymentYm) {
      const { tPaymentWork } = await ApiPaymentWork.createPaymentWork(paymentYm)
      commit('setPaymentWork', { tPaymentWork })
    },
    /**
     * @param {ActionContext<StorePaymentState>} { state, commit }
     */
    async completePaymentWork ({ state, commit }, paymentYm) {
      const { tPaymentWork } = await ApiPaymentWork.completePaymentWork(paymentYm)
      commit('setPaymentWork', { tPaymentWork })
    },
  }
}