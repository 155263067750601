/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Service Worker */
// import './registerServiceWorker'

/* Validater */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import './vee-validate'

/* Vue. Main component */
import App from './App.vue'
import ValidationInput from '@/components/Shared/ValidationInput.vue'
import ValidationField from '@/components/Shared/ValidationField.vue'
import Datepicker from '@/components/Shared/Datepicker.vue'
import InstructorLabel from '@/components/Shared/InstructorLabel.vue'
import ProgramLabel from '@/components/Shared/ProgramLabel.vue'

import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'

dayjs.locale(ja)

/* Detect mobile layout */
store.dispatch('layoutMobileToggle')

window.onresize = () => {
  store.dispatch('layoutMobileToggle')
}

/* Collapse mobile aside menu on route change */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)
  store.commit('overlayToggle', false)
  store.commit('asideActiveForcedKeyToggle', null)
})

console.log(process.env.VUE_APP_API_HOST, 'VUE_APP_API_HOST')

Vue.config.productionTip = false

Vue.use(Buefy, {
  defaultInputHasCounter: false,
  defaultUseHtml5Validation: false,
  defaultStatusIcon: false,
  defaultProgrammaticPromise: true
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationInput', ValidationInput)
Vue.component('ValidationField', ValidationField)
Vue.component('Datepicker', Datepicker)
Vue.component('InstructorLabel', InstructorLabel)
Vue.component('ProgramLabel', ProgramLabel)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
