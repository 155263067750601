import { DateFormat, TimeFormat } from '@/config/const'
import { Enums, TypeEnum } from '@/types/enum' // eslint-disable-line no-unused-vars
import { assign } from '@/util'
import dayjs from 'dayjs'
import { MInstructor } from './typeInstructor' // eslint-disable-line no-unused-vars

export class TLesson {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.periodYm = data.periodYm
    this.area = Enums.Area.of(data.area)
    this.repeatId = data.repeat_id ? Number(data.repeat_id) : null

    this.facility = Enums.LessonFacility.of(data.facility)

    this.programId = Number(data.program_id) || 0

    this.instructorIds = data.instructor_ids.map(id => Number(id) || 0)

    this.targetDate = dayjs(data.target_date, DateFormat)
    this.targetMonth = this.targetDate.format('YYYYMM')
    this.startDate = dayjs(`${data.target_date} ${data.start_time}`, `${DateFormat} ${TimeFormat}`)
    this.startTime = dayjs(`20000101 ${data.start_time}`, `${DateFormat} ${TimeFormat}`)
    this.minutes = Number(data.minutes) || 0
    this.endDate = this.startDate.add(this.minutes, 'minutes')

    /** @type {string} */
    this.note = data.note || ''

    /** @type {boolean} */
    this.active = data.active

    /** @type {boolean} */
    this.didCheckined = !!data.checkin_flag
  }

  get facilitySortVal () {
    return this.facility.label
  }
  get startDateSortVal () {
    return this.startDate.format('YYYYMMDDHHmm')
  }
  get weekSortVal () {
    return this.startDate.day()
  }
}

export class LessonForm {
  constructor () {
    this.id = 0
    /** @type {dayjs.Dayjs[]} */
    this.targetDates = []
    /** @type {dayjs.Dayjs} */
    this.targetDate = null
    this.active = true

    /** @type {TypeEnum} */
    this.facility = null
    /** @type {MProgram} */
    this.mProgram = null

    /** @type {MInstructor[]} */
    this.mInstructors = []

    /** @type {dayjs.Dayjs} */
    this.startTime = null // dayjs().set('hour', 12).set('minute', 0)
    /** @type {number} */
    this.minutes = null
    this.note = ''
  }

  get calcEndTime () {
    const { startTime, minutes } = this
    if (startTime && minutes) {
      return startTime.add(minutes, 'm')
    }
    return null
  }
  get mProgramAlt () {
    return this.mProgram
  }
  /** @param {MProgram} val */
  set mProgramAlt (val) {
    this.mProgram = val
    if (val && val.minutes && !this.minutes) {
      this.minutes = val.minutes
    }
  }

  /** @param {TLesson} tLesson */
  fill (tLesson) {
    assign(this, tLesson)
    return this
  }

  get params () {
    return {
      id: this.id,
      target_date: this.targetDate ? this.targetDate.format(DateFormat) : null,
      target_dates: this.targetDates.map(dayObj => dayObj.format(DateFormat)),
      facility: this.facility.value,
      program_id: this.mProgram.id,
      instructor_ids: this.mInstructors.map(row => row.id),
      start_time: this.startTime.format(TimeFormat),
      minutes: Number(this.minutes) || 0,
      note: this.note || '',
      active: this.active,
    }
  }
}

export class LessonTableFilter {
  constructor (facilityOptions) {
    this.facilities = facilityOptions || []
  }
  setFacilities (facilityOptions) {
    this.facilities = facilityOptions
  }
  /**
   * @param {TLesson} tLesson
   */
  test (tLesson) {
    if (!this.facilities.includes(tLesson.facility)) {
      return false
    }
    return true
  }
}
