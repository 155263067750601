import { DateFormat, TimeFormat } from '@/config/const'
import dayjs from 'dayjs'
import { Enums, TypeEnum } from './enum' // eslint-disable-line no-unused-vars
import { TCheckin } from './typeCheckin'

export class TEvent {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.lessonId = Number(data.lesson_id)
    this.repeatId = Number(data.repeat_id)

    this.area = Enums.Area.of(data.area)
    this.facility = Enums.LessonFacility.of(data.facility)

    this.programId = Number(data.program_id) || 0

    this.instructorId = Number(data.instructor_id) || 0

    this.targetDate = dayjs(data.target_date, DateFormat)
    this.targetMonth = this.targetDate.format('YYYYMM')
    this.startDate = dayjs(`${data.target_date} ${data.start_time}`, `${DateFormat} ${TimeFormat}`)
    this.startTime = dayjs(`20000101 ${data.start_time}`, `${DateFormat} ${TimeFormat}`)
    this.minutes = Number(data.minutes) || 0
    this.endDate = this.startDate.add(this.minutes, 'minutes')

    /** @type {string} */
    this.note = data.note || ''

    this.isRepeat = !!Number(data.repeat_id)

    this.checkable = !!data.checkable

    this.checkinId = Number(data.checkin_id) || null
    /** @type {TCheckin} */
    this.tCheckin = data.checkin ? new TCheckin(data.checkin) : null
  }

  get hasCheckin () {
    return !!this.tCheckin
  }

  get isProxy () {
    return !!this.tCheckin && !!this.tCheckin.proxyId
  }

  get hasCheckinComment () {
    return !!this.tCheckin && !!this.tCheckin.note
  }

  get startDateSortVal () {
    return this.startDate.format('YYYYMMDDHHmm')
  }

  /** @return {TypeEnum} */
  get confirmStatus () {
    if (!this.tCheckin) {
      return Enums.CheckinConfirmStatus.None
    }
    if (!this.tCheckin.confirmFlag) {
      return Enums.CheckinConfirmStatus.Init
    }
    return Enums.CheckinConfirmStatus.Confirmed
  }

  get isConfirmed () {
    return this.confirmStatus.is('Confirmed')
  }

  get checkinSortVal () {
    return this.confirmStatus.value
  }

  get checkinAtSortVal () {
    return this.tCheckin ? this.tCheckin.checkinAtSortVal : '99999999999999'
  }

  get checkinAtLabel () {
    if (!this.hasCheckin) {
      return ''
    }
    return this.tCheckin.checkinAt.format('HH:mm')
  }

  revertCheckin () {
    this.checkinId = null
    this.tCheckin = null
  }
}

export class EventManageForm {
  constructor () {
    this.id = 0
    this.note = ''
  }
  /**
   * @param {TEvent} tEvent
   */
  fill (tEvent) {
    this.id = tEvent.id
    this.note = tEvent.note
    return this
  }
  get params () {
    return {
      id: this.id,
      note: this.note || '',
    }
  }
}

export class EventTableFilter {
  constructor () {
    /** @type {TypeEnum} */
    this.area = null
    this.confirmStatus = null
    this.showAll = false
    this.mInstructor = null

    this.facility = null
  }

  get areaAlt () {
    return this.area
  }
  set areaAlt (val) {
    this.area = val
    this.facility = null
  }

  /**
   * @param {TEvent} tEvent
   */
  test (tEvent) {
    if (this.area && tEvent.area !== this.area) {
      return false
    }
    if (this.facility && tEvent.facility !== this.facility) {
      return false
    }
    if (this.confirmStatus && tEvent.confirmStatus !== this.confirmStatus) {
      return false
    }
    if (!this.showAll && !tEvent.checkable && !tEvent.hasCheckin) {
      return false
    }
    if (this.mInstructor) {
      if (tEvent.instructorId === this.mInstructor.id) {
        return true
      }
      if (tEvent.tCheckin && tEvent.tCheckin.proxyId === this.mInstructor.id) {
        return true
      }
      return false
    }
    return true
  }
}