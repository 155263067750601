
/**
 * バリデーションエラー業務例外
 *
 * @export
 * @class InValidServiceException
 * @extends {Error}
 */
export class InValidServiceException extends Error {
  constructor (data, message = 'InValidServiceException') {
    super(message)
    this.message = message || ''
    this.data = data
  }
}

export class ConfirmServiceException extends Error {
  constructor (message) {
    super(message)
    this.message = message || ''
  }
}

export class UnAuthoriedException extends Error {
  /**
   * APIレスポンスからインスタンスを生成する
   * @memberof UnAuthoriedException
   */
  constructor (data, message = 'UnAuthoriedException') {
    super(message)
  }

  static plsLogin () {
    window.location.reload()
  }
}

export default {}