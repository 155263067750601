
import { ConfirmServiceException, InValidServiceException, UnAuthoriedException } from '@/config/exception'

export default {
  data () {
    return {
      isLoading: false,
      invalidData: null,
    }
  },
  methods: {
    willBackOrReplace (route) {
      if (window.history.length) {
        this.$router.back()
      } else {
        this.$router.replace(route)
      }
    },
    alertSuccess (message) {
      this.$buefy.snackbar.open({
        message: message,
        type: 'is-success',
        position: 'is-top',
        duration: 2000
      })
    },
    toastInfo (message) {
      this.$buefy.toast.open({
        message: message,
        position: 'is-top',
        type: 'is-info',
        duration: 1000
      })
    },
    alertInvalid (message) {
      this.$buefy.snackbar.open({
        message: message,
        type: 'is-warning',
        position: 'is-bottom',
        actionText: '閉じる',
        duration: 4000,
        queue: false
      })
    },
    handleError (error, options = {}) {
      if (error instanceof ConfirmServiceException) {
        this.$buefy.dialog.confirm({
          message: error.message.replace(/\n/g, '<br />'),
          hasIcon: true,
          type: 'is-warning',
          confirmText: '続ける',
          cancelText: 'キャンセル',
          onCancel: () => {
            this.isLoading = false
          },
          onConfirm: () => {
            if (options.onConfirm) {
              options.onConfirm()
            }
          }
        })
        return
      }
      this.isLoading = false
      if (error instanceof InValidServiceException) {
        this.invalidData = error.data
        this.alertInvalid(error.message || '入力内容をご確認ください')
        return
      }
      if (error instanceof UnAuthoriedException) {
        console.log('UnAuthoriedException')
        UnAuthoriedException.plsLogin()
        return
      }
      console.log(error)
      this.alertInvalid('エラーが発生しました。管理者にお問い合わせください。')
    },
  },
}