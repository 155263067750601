<template>
  <b-tag v-if="tEvent.isConfirmed" type="is-success">承認</b-tag>
  <b-tag v-else-if="tEvent.hasCheckin" type="is-info">済</b-tag>
  <b-tag v-else type="is-white">未実施</b-tag>
</template>

<script>
import { TEvent } from '@/types/typeEvent'
export default {
  props: {
    tEvent: TEvent
  }
}
</script>

<style>
</style>