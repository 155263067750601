// eslint-disable-next-line camelcase
import { required, confirmed, alpha_num, email, regex, numeric, integer, min_value, max_value } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import dayjs from 'dayjs'

extend('required', {
  ...required,
  message: '必須項目です'
})

extend('alpha_num', {
  // eslint-disable-next-line camelcase
  ...alpha_num,
  message: '半角英数字で入力してください'
})

extend('email', {
  ...email,
  message: 'メールアドレス形式で入力してください'
})

extend('confirmed', {
  ...confirmed,
  message: 'パスワードが一致していません'
})

extend('length', {
  validate (value, { len }) {
    return value.length === Number(len)
  },
  params: ['len'],
  message: '{len}文字で入力してください'
})
extend('min', {
  validate (value, { length }) {
    return value.length >= Number(length)
  },
  params: ['length'],
  message: '{length}文字以上で入力してください'
})
extend('max', {
  validate (value, { length }) {
    return value.length <= Number(length)
  },
  params: ['length'],
  message: '{length}文字以内で入力してください'
})
extend('minmax', {
  validate (value, args) {
    const length = value.length

    return length >= args.min && length <= args.max
  },
  params: ['min', 'max'],
  message: '{min}文字以上{max}文字以内で入力してください'
})
extend('numeric', {
  ...numeric,
  message: '半角数字で入力してください'
})
extend('integer', {
  ...integer,
  message: '半角数字で入力してください'
})
extend('min_value', {
  // eslint-disable-next-line camelcase
  ...min_value,
  message: '{min}以上で入力してください'
})

extend('max_value', {
  // eslint-disable-next-line camelcase
  ...max_value,
  message: '{max}以下で入力してください'
})

extend('percent', {
  validate (value, args) {
    if (value === '') {
      return true
    }
    const num = Number(value)
    if (num === 0) {
      return true
    }
    if (!num) {
      return false
    }
    if (num < 0 || num > 100) {
      return false
    }
    return true
  },
  message: '0～100以内、小数点2桁以内で入力してください。'
})
extend('regex_userid', {
  validate (value, args) {
    return regex.validate(value, { regex: /^[a-zA-Z0-9-_.]+$/ })
  },
  message: '半角の英数字、-、_(アンダーバー)、.(ピリオド)以外は入力できません。'
})
extend('regex_tel', {
  validate (value, args) {
    return regex.validate(value, { regex: /^[0-9-]+$/ })
  },
  message: '半角数字、ハイフン以外は入力できません。'
})
extend('regex_email', {
  validate (value, args) {
    return regex.validate(value, { regex: /^[a-zA-Z0-9-_.@]+$/ })
  },
  message: '半角の英数字、-、_(アンダーバー)、.(ピリオド)、@以外は入力できません。'
})
extend('regax_date', {
  validate (value) {
    if (!value) {
      return true
    }
    const dayObj = dayjs(value, 'YYYYMMDD')
    return dayObj.isValid() && dayObj.format('YYYYMMDD') === value
  },
  message: '西暦 4桁:年 2桁:月 2桁で入力してください'
})