export const DatetimeFormat = 'YYYY-MM-DD HH:mm:ss'
export const DateFormat = 'YYYYMMDD'
export const TimeFormat = 'HHmm'
export const MonthKeyFormat = 'YYYYMM'
export const ALLWEEKS = {
  1: '月',
  2: '火',
  3: '水',
  4: '木',
  5: '金',
  6: '土',
  0: '日',
}
export const WEEKS = {
  1: '月',
  2: '火',
  3: '水',
  4: '木',
  5: '金',
  6: '土',
}
export const AREAS = {
  A: 'アミュ',
  U: '宇宿',
  S: '川内',
  I: '出水',
}

export const SOCKET_MESSAGE_TYPES = {
  UpdateEvent: 1,
  ConfirmCheckin: 2,
  DeleteEvent: 3,
}

export const LOCALSTRAGE_SCHEDULE_TARGET_YM_KEY = 'schedule.period_ym'