
import { ActionContext } from 'vuex' // eslint-disable-line no-unused-vars
import { ApiInstructor, ApiLesson, ApiProgram, ApiRepeat } from '@/api'
import { MProgram, MProgramGroup } from '@/types/typeProgram' // eslint-disable-line no-unused-vars
import { MInstructor } from '@/types/typeInstructor' // eslint-disable-line no-unused-vars
import { todayPeriodYm, forgePeriod, forgeWeekDays, Collection, selectablePeriodYms } from '@/util'
import { TypeEnum } from '@/types/enum' // eslint-disable-line no-unused-vars
import { TLesson } from '@/types/typeLesson' // eslint-disable-line no-unused-vars
import { TRepeat } from '@/types/typeRepeat' // eslint-disable-line no-unused-vars
import { LOCALSTRAGE_SCHEDULE_TARGET_YM_KEY } from '@/config/const'

const StoreScheduleState = {
  dataState: false,
  /** @type {string} */
  periodYm: todayPeriodYm(),
  /** @type {string} */
  selectablePeriodYms: selectablePeriodYms(),
  /** @type {MProgram} */
  mPrograms: [],
  /** @type {MProgramGroup} */
  mProgramGroups: [],
  /** @type {MInstructor} */
  mInstructors: [],
  /** @type {TLesson[]} */
  tLessons: [],
  /** @type {TRepeat[]} */
  tRepeats: [],
  calendarDataState: false, // spot以外のtLessonを読み込んだかどうか
  /** @type {TypeEnum} */
  calendarFacility: null,
}

export default {
  namespaced: true,
  state: () => StoreScheduleState,
  getters: {
    /** @param {StoreScheduleState} state */
    currentPeriod (state) {
      return forgePeriod(state.periodYm)
    },
    /** @param {StoreScheduleState} state */
    currentSundays (state, getters) {
      const { minDay, maxDay } = getters.currentPeriod
      return forgeWeekDays(minDay, maxDay, 0)
    },
    /** @param {StoreScheduleState} state */
    spotLessons (state) {
      return state.tLessons.filter(tLesson => !tLesson.repeatId)
    },
    /** @param {StoreScheduleState} state */
    findRepeat: (state) => (repeatId) => {
      return state.tRepeats.find(row => row.id === repeatId)
    },
    /**
     * @param {StoreScheduleState} state
     */
    mPGroupMap (state) {
      return new Collection(state.mProgramGroups).forgeMap('id')
    },
    /**
     * @param {StoreScheduleState} state
     */
    findMPGroup: (state, getters) => (id) => {
      return id ? getters.mPGroupMap[id] : null
    },
    /**
     * @param {StoreScheduleState} state
     */
    mProgramMap (state) {
      return new Collection(state.mPrograms).forgeMap('id')
    },
    /**
     * @param {StoreScheduleState} state
     */
    findMProgram: (state, getters) => (id) => {
      return id ? getters.mProgramMap[id] : null
    },
    /**
     * @param {StoreScheduleState} state
     */
    mInstructorsMap (state) {
      return new Collection(state.mInstructors).forgeMap('id')
    },
    /**
     * @param {StoreScheduleState} state
     */
    findMInstructor: (state, getters) => (id) => {
      return id ? getters.mInstructorsMap[id] : null
    },
    /**
     * @param {StoreScheduleState} state
     */
    findMInstructors: (state, getters) => (ids) => {
      return ids ? ids.map(id => getters.findMInstructor(id)).filter(row => !!row) : []
    },
  },
  mutations: {
    fill (state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
      })
    },
    setPeriodYm (state, periodYm) {
      state.periodYm = periodYm
      if (window.localStorage) {
        window.localStorage.setItem(LOCALSTRAGE_SCHEDULE_TARGET_YM_KEY, periodYm)
      }
      state.tLessons = []
      state.tRepeats = []
    },
    clearData (state) {
      state.tLessons = []
      state.tRepeats = []
      state.calendarDataState = false
    },
    setCalendarFacility (state, facility) {
      state.calendarFacility = facility
    },
    /**
     * @param {StoreScheduleState} state
     * @param {{tLessons: TLesson[]}} {}
     */
    addLessons (state, { tLessons }) {
      tLessons.forEach(row => { row.rowClass = 'is-created' })
      state.tLessons = new Collection(state.tLessons).setProp('rowClass', null).add(tLessons).members
    },
    /**
     * @param {StoreScheduleState} state
     * @param {{tLesson: TLesson[]}} {}
     */
    editLesson (state, { tLesson }) {
      tLesson.rowClass = 'is-updated'
      state.tLessons = new Collection(state.tLessons).setProp('rowClass', null).set(tLesson).members
    },
    /**
     * @param {StoreScheduleState} state
     * @param {{lessonId: number}} {}
     */
    removeLesson (state, { lessonId }) {
      state.tLessons = new Collection(state.tLessons).setProp('rowClass', null).remove(lessonId).members
    },
    /**
     * @param {StoreScheduleState} state
     * @param {{tRepeat: TRepeat, tLessons: TLesson[]}} {}
     */
    addRepeat (state, { tRepeat, tLessons }) {
      tRepeat.rowClass = 'is-created'
      state.tRepeats = new Collection(state.tRepeats).setProp('rowClass', null).sort('defaultSortVal').add([tRepeat]).members
      state.tLessons = state.tLessons.concat(tLessons)
    },
    /**
     * @param {StoreScheduleState} state
     * @param {{tRepeat: TRepeat, tLessons: TLesson[]}} {}
     */
    editRepeat (state, { tRepeat, tLessons }) {
      tRepeat.rowClass = 'is-updated'
      state.tRepeats = new Collection(state.tRepeats).setProp('rowClass', null).sort('defaultSortVal').set(tRepeat).members
      state.tLessons = state.tLessons.filter(row => row.repeatId !== tRepeat.id).concat(tLessons)
    },
    /**
     * @param {StoreScheduleState} state
     * @param {{repeatId: number}} {}
     */
    removeRepeat (state, { repeatId }) {
      state.tRepeats = new Collection(state.tRepeats).setProp('rowClass', null).remove(repeatId).members
      state.tLessons = state.tLessons.filter(tLesson => tLesson.repeatId !== repeatId)
    },
  },
  actions: {
    /**
     * @param {ActionContext<StoreScheduleState>} { state, commit }
     */
    async forgeStates ({ state, commit }, force) {
      console.log(!!force, 'storeSchedule.forgeStates')
      if (!force && state.dataState) {
        return
      }

      if (window.localStorage && window.localStorage.getItem(LOCALSTRAGE_SCHEDULE_TARGET_YM_KEY)) {
        commit('setPeriodYm', window.localStorage.getItem(LOCALSTRAGE_SCHEDULE_TARGET_YM_KEY))
      }
      const [{ mInstructors }, { mPrograms, mProgramGroups }] = await Promise.all([
        ApiInstructor.fetchInstructors(),
        ApiProgram.fetchPrograms(),
      ])
      commit('fill', { mPrograms, mProgramGroups, mInstructors, dataState: true })
    },
    /**
     * @param {ActionContext<StoreScheduleState>} { state, commit }
     * @param {{area: TypeEnum}} {}
     */
    async forgeData ({ state, commit }, { area }) {
      console.log(area.value, 'storeSchedule.forgeData')
      const [{ tLessons }, { tRepeats }] = await Promise.all([
        ApiLesson.fetchLessons(state.periodYm, area.value),
        ApiRepeat.fetchRepeats(state.periodYm, area.value)
      ])
      commit('fill', {
        tLessons: new Collection(tLessons).sort('startDateSortVal').members,
        tRepeats: new Collection(tRepeats).sort('defaultSortVal').members
      })
    },
    /**
     * @param {ActionContext<StoreScheduleState>} { state, commit }
     * @param {{area: TypeEnum}} {}
     */
    async forgeCarendarData ({ state, commit }, { area }) {
      const { tLessons } = await ApiLesson.fetchLessons(state.periodYm, area.value, 'all')
      commit('fill', {
        tLessons: new Collection(tLessons).sort('startDateSortVal').members,
        calendarDataState: true
      })
    },
  }
}
