<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
    <CardComponent
      :isScrollable="true"
      :title="cardTitle"
      icon="ballot"
      style="width: 600px"
      headerIcon="close"
      hasBottomSlot
      @header-icon-click="$emit('close')"
      v-if="tEvent"
    >
      <form>
        <b-field label="時間" horizontal>
          <span v-if="tEvent.startDate && tEvent.endDate"
            >{{ tEvent.startDate.format('YYYY/MM/DD (dd) HH:mm') }} -
            {{ tEvent.endDate.format('HH:mm') }}</span
          >
          <span v-else>-</span>
        </b-field>
        <b-field label="担当" horizontal>
          <InstructorLabel
            v-if="mInstructor"
            :mInstructor="mInstructor"
            position="is-bottom"
          />
          <span v-else>-</span>
        </b-field>
        <b-field label="ステータス" horizontal>
          <CheckinStatusTag :tEvent="tEvent" />
        </b-field>
        <div v-if="tCheckin">
          <b-field label="代行" horizontal>
            <b-switch v-model="checkinManageForm.proxyFlag"></b-switch>
          </b-field>
          <b-field label="" horizontal v-if="checkinManageForm.proxyFlag">
            <CheckinInstrcutorSearch
              v-model="checkinManageForm.proxy"
              :exclude="mInstructor ? mInstructor.id : null"
              icon="human"
            />
          </b-field>
          <b-field label="実績時間" horizontal>
            <MinutesField
              v-model="checkinManageForm.minutes"
              rules="required"
              :presets="[10, 15]"
            />
          </b-field>
          <b-field label="実績備考" horizontal message="実施確認時に入力されます">
            <b-input
              type="textarea"
              v-model="checkinManageForm.note"
              maxlength="500"
              :rows="2"
            />
          </b-field>
        </div>
        <div v-else>
          <b-field label="メモ" horizontal message="未実施の理由等を記載します">
            <b-input
              type="textarea"
              v-model="eventManageForm.note"
              maxlength="500"
              :rows="2"
            />
          </b-field>
        </div>
      </form>
      <div slot="bottom" class="level p-4">
        <div class="level-left">
          <div class="level-item buttons">
            <b-button
              native-type="button"
              type="is-primary"
              :loading="isLoading"
              @click.prevent="handleSubmit(willSave)"
              >{{tCheckin ? '登録' : 'メモ登録'}}</b-button
            >
            <b-button
              type="is-light"
              @click="$emit('close')"
              :loading="isLoading"
              >閉じる</b-button
            >
          </div>
        </div>
        <div class="level-right">
          <div class="level-item buttons">
            <b-button
              type="is-warning"
              size="is-small"
              icon-left="check"
              v-if="!tCheckin"
              @click="willForceCheckin"
              >実施済みにする</b-button
            >
            <b-button
              type="is-danger"
              size="is-small"
              icon-left="backspace-outline"
              outlined
              v-else
              @click="willRevert"
              >未実施に戻す</b-button
            >
          </div>
        </div>
      </div>
    </CardComponent>
  </ValidationObserver>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { CheckinManageForm } from '@/types/typeCheckin'
import { mapActions, mapGetters } from 'vuex'
import CheckinStatusTag from './CheckinStatusTag.vue'
import CheckinInstrcutorSearch from './CheckinInstrcutorSearch.vue'
import MinutesField from '@/components/Shared/MinutesField.vue'
import { EventManageForm } from '@/types/typeEvent'
export default {
  mixins: [BaseMixin],
  props: {
    eventId: Number,
  },
  components: { CardComponent, CheckinStatusTag, CheckinInstrcutorSearch, MinutesField },
  computed: {
    ...mapGetters('storeCheckin', ['findEvent', 'findMProgram', 'findMInstructor']),
    tEvent () {
      return this.findEvent(this.eventId)
    },
    mProgram () {
      return this.tEvent ? this.findMProgram(this.tEvent.programId) : null
    },
    mInstructor () {
      return this.tEvent ? this.findMInstructor(this.tEvent.instructorId) : null
    },
    cardTitle () {
      const programName = this.mProgram ? this.mProgram.name : '-'
      const facilityLabel = this.tEvent && this.tEvent.facility ? this.tEvent.facility.label : '-'
      return `実績管理 ${programName} @${facilityLabel}`
    },
    tCheckin () {
      return this.tEvent ? this.tEvent.tCheckin || null : null
    }
  },
  data () {
    return {
      checkinManageForm: new CheckinManageForm(),
      eventManageForm: new EventManageForm()
    }
  },
  methods: {
    ...mapActions('storeCheckin', ['manageEvent', 'forceCheckin', 'manageCheckin', 'revertCheckin']),
    willForceCheckin () {
      this.isLoading = true
      this.forceCheckin({ eventId: this.tEvent.id }).then(() => {
        this.checkinManageForm.fill(this.tCheckin)
        this.toastInfo('実施済みに変更しました。')
        this.isLoading = false
      })
    },
    willRevert () {
      console.log('willRevert')
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        confirmText: '実行',
        cancelText: 'キャンセル',
        message: '実施確認を削除し、未実施状態に戻します',
        onConfirm: () => {
          this.isLoading = true
          this.revertCheckin({ tEvent: this.tEvent }).then(() => {
            this.checkinManageForm = new CheckinManageForm()
            this.toastInfo('未実施状態に戻しました。')
            this.isLoading = false
          })
        }
      })
    },
    willSave () {
      this.isLoading = true
      if (this.tCheckin) {
        this.manageCheckin(this.checkinManageForm).then(() => {
          this.$emit('close')
          this.isLoading = false
        }).catch(this.handleError)
        return
      }
      this.manageEvent(this.eventManageForm).then(() => {
        this.$emit('close')
        this.isLoading = false
      }).catch(this.handleError)
    },
  },
  mounted () {
    this.eventManageForm.fill(this.tEvent)
    if (this.tCheckin) {
      this.checkinManageForm.fill(this.tCheckin)
      this.checkinManageForm.proxy = this.findMInstructor(this.tCheckin.proxyId)
    }
  }
}
</script>
