import { DateFormat, TimeFormat } from '@/config/const'
import { assign } from '@/util'
import dayjs from 'dayjs'
import { TEvent } from './typeEvent' // eslint-disable-line no-unused-vars

export class TCheckin {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.lessonStartAt = dayjs(data.lesson_start_at, `${DateFormat} ${TimeFormat}`)
    this.checkinAt = dayjs(`${data.checkin_date} ${data.checkin_time}`, 'YYYYMMDD HHmmss')
    this.proxyId = data.proxy_id ? Number(data.proxy_id) || null : null
    /** @type {string} */
    this.note = data.note || ''
    this.minutes = Number(data.minutes) || 0

    this.confirmFlag = !!data.confirm_flag

    this.confirmFlagChange = this.confirmFlag

    // this.paymentStatus = Enums.CheckinPaymentStatus.of(data.payment_status)
  }
  get lessonStartAtSortVal () {
    return this.lessonStartAt.format('YYYYMMDDHHmm')
  }
  get checkinAtSortVal () {
    return this.checkinAt.format('YYYYMMDDHHmm')
  }
  setConfirmed (bool) {
    this.confirmFlag = bool
    this.confirmFlagChange = bool
  }
}

export class CheckinForm {
  /** @param {TEvent} tEvent */
  constructor (tEvent) {
    this.tEvent = tEvent
    this.id = 0
    this.proxyFlag = false
    this.proxyCode = ''
    this.password = ''
    this.note = ''
  }
  /**
   * @param {TCheckin} tCheckin
   */
  fill (tCheckin) {
    assign(this, tCheckin)
  }
  get params () {
    return {
      event_id: this.tEvent.id,
      id: this.id,
      password: this.password,
      proxy_flag: this.proxyFlag,
      proxy_code: this.proxyCode,
      note: this.note,
    }
  }
}

export class CheckinManageForm {
  constructor () {
    this.id = 0
    this.proxyFlag = false
    this.proxy = null
    this.minutes = 0
    this.note = ''
  }
  /**
   * @param {TCheckin} tCheckin
   */
  fill (tCheckin) {
    this.id = tCheckin.id
    this.proxyFlag = !!tCheckin.proxyId
    this.minutes = tCheckin.minutes
    this.note = tCheckin.note
    return this
  }
  get params () {
    return {
      id: this.id,
      proxy_id: this.proxyFlag && this.proxy ? this.proxy.id : null,
      minutes: Number(this.minutes) || 0,
      note: this.note || '',
    }
  }
}