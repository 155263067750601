
export class TypeEnum {
  /**
   * @param {string} name
   * @param {any} value
   * @param {string} label
   * @param {{}} options
   */
  constructor (name, value, label, options) {
    this.name = name
    this.value = value
    this.label = label
    this.options = options || {}
  }
  hashVal () {
    return this.value
  }
  is (name) {
    return this.name === name
  }
  /**
   * @param {string[]} names
   * @return {boolean}
   */
  in (names) {
    return names.includes(this.name)
  }
}
class TypeEnumGroup {
  /**
   * @param {TypeEnum[]} members
   */
  constructor (members) {
    this.list = members
    this.vmap = {}
    members.forEach((member) => {
      this.vmap[member.value] = member
      this[member.name] = member
    }, this)
  }
  /**
   * @return {TypeEnum}
   */
  of (value, d) {
    return this.vmap[value] || this[d] || this.list[0]
  }

  get options () {
    return this.list.reduce((map, member) => {
      map[member.value] = member.label
      return map
    }, {})
  }
}

const BaseAreaMembers = [
  new TypeEnum('Amu', 'AMU', 'アミュ'),
  new TypeEnum('Usuki', 'USK', '宇宿'),
  new TypeEnum('Sendai', 'SDI', '川内'),
  new TypeEnum('Izumi', 'IZM', '出水'),
  new TypeEnum('Aira', 'AIR', '姶良'),
]

export const Enums = {
  Role: new TypeEnumGroup([
    new TypeEnum('Admin', 'admin', '管理者'),
    new TypeEnum('Stuff', 'stuff', 'スタッフ'),
    new TypeEnum('Limited', 'limited', '限定ユーザ'),
    new TypeEnum('Instructor', 'instructor', 'インストラクター'),
    new TypeEnum('Shop', 'shop', '店舗'),
  ]),
  Area: new TypeEnumGroup(BaseAreaMembers),
  PaymentArea: new TypeEnumGroup(BaseAreaMembers.concat([new TypeEnum('Entrust', 'ENT', '指定管理')])),
  FeeCategory: new TypeEnumGroup([
    new TypeEnum('Jr', 'jr', 'Jr'),
    new TypeEnum('Yoga', 'yoga', 'ヨガ'),
    new TypeEnum('Aqua', 'aqua', 'アクア'),
    new TypeEnum('Other', 'other', 'その他'),
    new TypeEnum('Etc1', 'etc1', 'その他１'),
    new TypeEnum('Etc2', 'etc2', 'その他２'),
  ]),
  LessonFacility: new TypeEnumGroup([
    new TypeEnum('AmuStudioA', 'AmuStudioA', 'アミュAスタジオ', { area: 'AMU', shortLabel: 'Aスタジオ', poolFlag: false }),
    new TypeEnum('AmuStudioB', 'AmuStudioB', 'アミュBスタジオ', { area: 'AMU', shortLabel: 'Bスタジオ', poolFlag: false }),
    new TypeEnum('AmuPool', 'AmuPool', 'アミュプール', { area: 'AMU', shortLabel: 'プール', poolFlag: true }),
    new TypeEnum('AmuShort', 'AmuShort', 'アミュショートプログラム', { area: 'AMU', shortLabel: 'ショート', poolFlag: false }),

    new TypeEnum('UskStudioA', 'UskStudioA', '宇宿Aスタジオ', { area: 'USK', shortLabel: 'Aスタジオ', poolFlag: false }),
    new TypeEnum('UskStudioB', 'UskStudioB', '宇宿Bスタジオ', { area: 'USK', shortLabel: 'Bスタジオ', poolFlag: false }),
    new TypeEnum('UskStudioC', 'UskStudioC', '宇宿Cスタジオ', { area: 'USK', shortLabel: 'Cスタジオ', poolFlag: false }),
    new TypeEnum('UskPool', 'UskPool', '宇宿プール', { area: 'USK', shortLabel: 'プール', poolFlag: true }),
    new TypeEnum('UskConference', 'UskConference', '宇宿会議室', { area: 'USK', shortLabel: '会議室', poolFlag: false }),

    new TypeEnum('SdiStudio', 'SdiStudio', '川内スタジオ', { area: 'SDI', shortLabel: 'スタジオ', poolFlag: false }),
    new TypeEnum('SdiPool', 'SdiPool', '川内プール', { area: 'SDI', shortLabel: 'プール', poolFlag: true }),

    new TypeEnum('IzmStudio', 'IzmStudio', '出水スタジオ', { area: 'IZM', shortLabel: 'スタジオ', poolFlag: false }),
    new TypeEnum('IzmPool', 'IzmPool', '出水プール', { area: 'IZM', shortLabel: 'プール', poolFlag: true }),

    new TypeEnum('AirStudio', 'AirStudio', '姶良多目的室', { area: 'AIR', shortLabel: '多目的室', poolFlag: false }),
    new TypeEnum('AirConference', 'AirConference', '姶良会議室', { area: 'AIR', shortLabel: '会議室', poolFlag: false }),
    new TypeEnum('AirGroundA', 'AirGroundA', '姶良人工芝グランド', { area: 'AIR', shortLabel: '人工芝グランド', poolFlag: false }),
    new TypeEnum('AirArena', 'AirArena', '姶良アリーナ', { area: 'AIR', shortLabel: 'アリーナ', poolFlag: false }),
    new TypeEnum('AirTenis', 'AirTenis', '姶良テニスコート', { area: 'AIR', shortLabel: 'テニスコート', poolFlag: false }),
    new TypeEnum('AirGroundB', 'AirGroundB', '姶良多目的グランド', { area: 'AIR', shortLabel: '多目的グランド', poolFlag: false }),
  ]),
  CheckinConfirmStatus: new TypeEnumGroup([
    new TypeEnum('None', -1, '未実施'),
    new TypeEnum('Init', 0, '実施済み'),
    new TypeEnum('Confirmed', 1, '承認済み'),
  ]),
  CheckinPaymentStatus: new TypeEnumGroup([
    new TypeEnum('Init', 0, '未処理'),
    new TypeEnum('Staging', 1, '確定待ち'),
  ]),
  PaymentWorkFixStatus: new TypeEnumGroup([
    new TypeEnum('None', -1, '-'),
    new TypeEnum('Init', 0, '未確定'),
    new TypeEnum('Request', 1, '請求書確認中'),
    new TypeEnum('Complete', 2, '確認完了'),
  ]),
  BillingAnswer: new TypeEnumGroup([
    new TypeEnum('Init', 0, '未回答'),
    new TypeEnum('OK', 1, 'OK'),
    new TypeEnum('NG', 2, 'NG'),
  ]),
  FeeInstill: new TypeEnumGroup([
    new TypeEnum('Holiday', 1, '日祝+1000', { amount: 1000 }),
    new TypeEnum('UsukiSatAero', 2, '宇宿土曜オリジナルエアロ+500', { amount: 500 }),
  ]),

}
export default {}
