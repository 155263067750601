import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store'
import { UnAuthoriedException } from '@/config/exception'
import Dashboard from '@/views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('../views/FullPage.vue'),
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('../views/full-page/Login.vue')
      }
    ]
  },
  {
    path: '/error',
    component: () => import('../views/FullPage.vue'),
    children: [
      {
        path: '',
        name: 'Error',
        component: () => import('../views/full-page/Error.vue'),
        props: { isInCard: false }

      }
    ]
  },
  {
    path: '/',
    beforeEnter: (to, from, next) => {
      Store.dispatch('storeAuth/getAuth')
        .then(() => {
          Store.dispatch('forgeSocketStates')
          next()
        }).catch((err) => {
          if (err instanceof UnAuthoriedException) {
            next({ name: 'Login' })
            return
          }
          console.log(err)
          next({ name: 'Error' })
        })
    },
    component: () => import('../views/Root.vue'),
    children: [
      {
        path: '',
        beforeEnter: (to, from, next) => {
          if (Store.getters['storeAuth/isManageRole']) {
            next({ name: 'Dashboard' })
            return
          }
          if (Store.getters['storeAuth/isShopRole']) {
            next({ name: 'Checkin' })
            return
          }
          if (Store.getters['storeAuth/isInstructorRole']) {
            next({ name: 'Billing' })
            return
          }
          next({ name: 'Login' })
        },
      },
      {
        path: 'manage',
        beforeEnter: (to, from, next) => {
          if (!Store.getters['storeAuth/isManageRole']) {
            next({ name: 'Login' })
            return
          }
          next()
        },
        component: () => import('../views/RootManage.vue'),
        children: [
          {
            // 実施確認(管理)
            path: '',
            name: 'Dashboard',
            component: Dashboard
          },
          {
            // 支払い管理
            path: 'payment',
            component: () => import('../views/Payment.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              if (Store.getters['storeAuth/isLimitedRole']) {
                next({ name: 'Dashboard' })
                return
              }
              next()
            },
            children: [
              {
                path: '',
                name: 'Payment',
                component: () => import('../views/Payment/PaymentTop.vue'),
                props: true
              },
              {
                path: 'ym/:pathPaymentYm',
                component: () => import('../views/Payment/PaymentYm.vue'),
                props: true,
                children: [
                  {
                    path: 'soft',
                    name: 'SoftCheckins',
                    component: () => import('../views/Payment/SoftCheckins.vue'),
                    props: true
                  },
                  {
                    path: 'work',
                    name: 'PaymentWork',
                    component: () => import('../views/Payment/PaymentWork.vue'),
                    props: true
                  },
                  {
                    path: 'instructor/:pathInstructorId',
                    name: 'PaymentInstructor',
                    component: () => import('../views/Payment/PaymentInstructor.vue'),
                    props: true
                  }
                ]
              },
            ]
          },
          {
            // スケジュール設定
            path: 'schedule/:pathArea',
            name: 'Schedule',
            component: () => import('../views/Schedule.vue'),
            props: true,
          },
          {
            path: 'master',
            component: () => import('../views/AbstructRouter.vue'),
            props: true,
            beforeEnter: (to, from, next) => {
              if (Store.getters['storeAuth/isLimitedRole']) {
                next({ name: 'Dashboard' })
                return
              }
              next()
            },
            children: [
              {
                // マスタ：インスト設定
                path: 'instructors',
                name: 'Instructors',
                component: () => import('../views/Instructors.vue'),
                props: true,
              },
              {
                // マスタ：祝日・休館日設定
                path: 'holidays',
                name: 'Holidays',
                component: () => import('../views/Holidays.vue'),
                props: true,
              },
              {
                // マスタ：プログラム設定
                path: 'programs',
                name: 'Programs',
                component: () => import('../views/Programs.vue'),
                props: true,
              },
              {
                // マスタ：プログラム設定：グループ管理
                path: 'programs/groups',
                name: 'ProgramGroups',
                component: () => import('../views/ProgramGroups.vue'),
                props: true,
              },
              {
                // マスタ：ユーザー設定
                path: 'users',
                name: 'Users',
                component: () => import('../views/Users.vue'),
                props: true,
                beforeEnter: (to, from, next) => {
                  if (!Store.getters['storeAuth/isAdminRole']) {
                    next({ name: 'Dashboard' })
                    return
                  }
                  next()
                },
              }
            ]
          },
        ]
      },
      {
        path: 'outer',
        beforeEnter: (to, from, next) => {
          if (!Store.getters['storeAuth/isOuterRole']) {
            next({ name: 'Login' })
            return
          }
          next()
        },
        component: () => import('../views/RootOuter.vue'),
        children: [
          {
            // 実施確認(店舗端末)
            path: 'checkin',
            name: 'Checkin',
            component: () => import('../views/Outer/Checkin.vue'),
            props: true
          },
          {
            // 請求書確認(インストラクター)
            path: 'billing',
            name: 'Billing',
            component: () => import('../views/Outer/Billing.vue'),
            props: true
          },
        ]
      }
    ]
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
