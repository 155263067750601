import dayjs from 'dayjs'
import moji from 'moji'
import { MonthKeyFormat } from './config/const'

export const genUniquekey = (() => {
  let key = 0
  return () => {
    key += 1
    return key
  }
})()

export class Collection {
  /** @param {{id: number}[]} members */
  constructor (members) {
    this.members = members
  }

  /** @param {{id: number}} item */
  set (item) {
    if (this.members.some(member => member.id === item.id)) {
      this.members = this.members.map(member => member.id === item.id ? item : member)
    } else {
      this.members.push(item)
    }
    return this
  }
  /** @param {{id: number}[]} items */
  replaceOrPush (items) {
    const itemIds = items.map(item => item.id)
    const filtered = this.members.filter(member => !itemIds.includes(member.id))
    this.members = filtered.concat(items)
    return this
  }
  add (items) {
    this.members = this.members.concat(items)
    return this
  }
  /** @param {number} id */
  remove (id) {
    this.members = this.members.filter(member => member.id !== id)
    return this
  }
  /**
   * @param {string} prop
   * @param {string} direction
   *  */
  sort (prop, direction) {
    this.members.sort((a, b) => {
      if (a[prop] === b[prop]) {
        return 0
      }
      if (a[prop] < b[prop]) {
        return direction === 'asc' ? -1 : 1
      }
      return direction === 'asc' ? 1 : -1
    })
    return this
  }
  setProp (prop, value) {
    this.members.forEach(row => { row[prop] = value })
    return this
  }
  forgeMap (prop) {
    return this.members.reduce((map, row) => {
      map[row[prop]] = row
      return map
    }, {})
  }
}

export const todayPeriodYm = () => {
  return dayjs().format(MonthKeyFormat)
}

/** @param {string} periodYm */
export const forgePeriod = periodYm => {
  const dayObj = dayjs(periodYm, MonthKeyFormat)
  const minDay = dayObj.startOf('month')
  const maxDay = dayObj.endOf('month')
  return {
    key: periodYm,
    dayObj,
    minDay,
    maxDay,
    label: dayObj.format('YYYY年M月')
  }
}
export const selectablePeriodYms = () => {
  const today = dayjs()
  return [-4, -3, -2, -1, 0, 1, 2, 3, 4].map(d => today.add(d, 'months').format(MonthKeyFormat))
}

/** @param {string} ym */
export const parsePaymentYm = ym => {
  const date = dayjs(ym, MonthKeyFormat)
  const minDay = date.subtract(1, 'months').set('date', 16)
  const maxDay = date.set('date', 15)
  return {
    key: ym,
    date,
    minDay,
    maxDay,
    ymLabel: date.format('YYYY年MM月'),
    rangeLabel: `${minDay.format('YYYY/MM/DD')}〜${maxDay.format('MM/DD')}`

  }
}

/**
 * @param {dayjs.Dayjs} minDay
 * @param {dayjs.Dayjs} maxDay
 * @param {number} weekNo
 * */
export const forgeWeekDays = (minDay, maxDay, weekNo) => {
  const dayObjs = []
  let current = dayjs(minDay)
  while (current.day() !== Number(weekNo)) {
    current = current.add(1, 'day')
  }
  while (!current.isAfter(maxDay, 'day')) {
    dayObjs.push(current)
    current = current.add(7, 'day')
  }
  return dayObjs
}

export const toHanKana = (hiragana) => {
  return hiragana ? moji(hiragana).convert('HG', 'KK').convert('ZK', 'HK').toString() : ''
}

export const assign = (obj1, obj2) => {
  Object.keys(obj2).forEach(function (key) {
    if (key !== 'key' && Object.prototype.hasOwnProperty.call(obj1, key)) {
      obj1[key] = obj2[key]
    }
  })
}
