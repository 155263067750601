import { Enums } from '@/types/enum'
import { assign } from '@/util'

export class MUser {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.code = data.code || ''
    this.name = data.name || ''
    this.role = Enums.Role.of(data.role)
    this.area = data.area ? Enums.Area.of(data.area) : null
    /** @type {string} */
    this.note = data.note || ''
    /** @type {boolean} */
    this.active = data.active
  }

  get areaSortVal () {
    return this.area ? this.area.value : '0'
  }
  get roleSortVal () {
    return this.role.value
  }
  get activeSortVal () {
    return this.active ? 1 : 0
  }
  get isShop () {
    return this.role.is('Shop')
  }
}

export class UserForm {
  constructor () {
    this.id = null
    this.code = ''
    this.name = ''
    this.role = Enums.Role.Stuff
    this.area = null
    this.note = ''
    this.active = true
  }
  /**
   * @param {MUser} mUser
   */
  fill (mUser) {
    assign(this, mUser)
  }
  get params () {
    return {
      id: this.id,
      code: this.code,
      name: this.name,
      role: this.role.value,
      area: this.area ? this.area.value : null,
      note: this.note,
      active: this.active
    }
  }
}

export class UserPasswordForm {
  constructor () {
    this.password = ''
    this.confirmation = ''
    this.isShopPassword = false
  }

  get params () {
    return {
      password: this.password,
      shop_password: this.isShopPassword,
    }
  }
}

export class UserTableFilter {
  constructor () {
    this.text = ''
    this.role = null
    this.area = null
  }

  /**
   * @param {MUser} user
   */
  test (user) {
    const search = `${user.code}${user.name}${user.note}`
    if (this.text && !search.includes(this.text)) {
      return false
    }
    if (this.role && this.role !== user.role) {
      return false
    }
    if (this.area && this.area !== user.area) {
      return false
    }
    return true
  }
}