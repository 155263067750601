
import { ActionContext } from 'vuex' // eslint-disable-line no-unused-vars
import { AuthUser, AuthForm } from '@/types/auth' // eslint-disable-line no-unused-vars
import { ApiAuth } from '@/api'

const StoreAuthState = {
  /** @type {AuthUser} */
  authUser: null,
}

export default {
  namespaced: true,
  state: () => StoreAuthState,
  mutations: {
    fill (state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
      })
    },
  },
  getters: {
    /**
     * @param {StoreAuthState} state
     * @returns {boolean}
     */
    isAdminRole (state) {
      return !!state.authUser && state.authUser.role.is('Admin')
    },
    /**
     * @param {StoreAuthState} state
     * @returns {boolean}
     */
    isLimitedRole (state) {
      return !!state.authUser && state.authUser.role.is('Limited')
    },
    /**
     * @param {StoreAuthState} state
     * @returns {boolean}
     */
    isShopRole (state) {
      return !!state.authUser && state.authUser.role.is('Shop')
    },
    /**
     * @param {StoreAuthState} state
     * @returns {boolean}
     */
    isInstructorRole (state) {
      return !!state.authUser && state.authUser.role.is('Instructor')
    },
    /**
     * @param {StoreAuthState} state
     * @returns {boolean}
     */
    isManageRole (state) {
      return !!state.authUser && state.authUser.role.in(['Admin', 'Stuff', 'Limited'])
    },
    /**
     * @param {StoreAuthState} state
     * @returns {boolean}
     */
    isOuterRole (state) {
      return !!state.authUser && state.authUser.role.in(['Instructor', 'Shop'])
    },
    /**
     * @param {StoreAuthState} state
     * @returns {boolean}
     */
    hasCalcPermission (state) {
      return !!state.authUser && state.authUser.role.in(['Admin', 'Stuff'])
    },
  },
  actions: {
    /**
     * @param {ActionContext<StoreAuthState>} { state, commit }
     */
    async getAuth ({ commit }) {
      const authUser = await ApiAuth.getAuth()
      commit('fill', { authUser })
    },
    /**
     * @param {ActionContext<StoreAuthState>} { state, commit }
     * @param {{form: AuthForm}} {}
     */
    async login ({ commit }, { authForm }) {
      const authUser = await ApiAuth.login(authForm)
      commit('fill', { authUser })
    },
    /**
     * @param {ActionContext<StoreAuthState>} { state, commit }
     */
    async logout ({ commit }) {
      await ApiAuth.logout()
      commit('fill', { authUser: null })
    },
  }
}
