<template>
  <b-field
    :grouped="grouped"
    :group-multiline="groupedMultiline"
    :addons="addons"
  >
    <div class="control" v-if="noneLabel">
      <b-radio
        v-model="newValue"
        :native-value="null"
        @input="input"
        :type="type"
        :disabled="disabled"
      >
        {{ noneLabel }}
      </b-radio>
    </div>
    <div v-for="member in members" class="control" :key="member.value">
      <b-radio
        v-model="newValue"
        :native-value="member"
        @input="input"
        :type="type"
        :disabled="disabled"
      >
        {{ short ? member.options.shortLabel : member.label }}
      </b-radio>
    </div>
  </b-field>
</template>

<script>
export default {
  props: {
    members: {
      type: Array,
      default: () => []
    },
    noneLabel: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, Object],
      default: null
    },
    grouped: {
      type: Boolean,
      default: false
    },
    groupedMultiline: {
      type: Boolean,
      default: false
    },
    addons: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    short: Boolean
  },
  data () {
    return {
      newValue: null
    }
  },
  created () {
    this.newValue = this.value
  },
  methods: {
    input () {
      this.$emit('input', this.newValue)
    }
  },
  watch: {
    value (newValue) {
      this.newValue = newValue
    }
  }
}
</script>
