<template>
  <b-table
    :loading="isLoading"
    :paginated="paginated"
    :per-page="perPage"
    :striped="true"
    narrowed
    :default-sort="defaultSort"
    :data="rows"
    :row-class="(row, index) => row.tEvent.rowClass"
    style="font-size: 0.9rem"
  >
    <b-table-column label="" width="3em" centered v-slot="{ row }">
      <b-icon icon="bookmark-outline" v-if="row.tEvent.isRepeat" />
      <b-icon icon="shield-star-outline" v-else />
    </b-table-column>
    <b-table-column
      label="施設"
      field="facilitySortVal"
      sortable
      v-slot="{ row }"
    >
      <span v-if="row.tEvent.facility">{{ row.tEvent.facility.label }}</span>
      <span v-else>-</span>
    </b-table-column>
    <b-table-column
      label="日程"
      width="8em"
      field="startDateSortVal"
      sortable
      v-slot="{ row }"
    >
      {{ row.tEvent.startDate.format('HH:mm') }} -
      {{ row.tEvent.endDate.format('HH:mm') }}
    </b-table-column>
    <b-table-column label="時間" width="4em" v-slot="{ row }">
      <span
        v-if="row.tEvent.tCheckin"
        :class="{
          'has-text-danger': row.tEvent.minutes != row.tEvent.tCheckin.minutes,
        }"
        >{{ row.tEvent.tCheckin.minutes }}分</span
      >
      <span v-else>{{ row.tEvent.minutes }}分</span>
    </b-table-column>
    <b-table-column label="プログラム" v-slot="{ row }">
      <CheckinProgramLabel
        :programId="row.tEvent.programId"
        position="is-left"
      />
    </b-table-column>
    <b-table-column label="担当" v-slot="{ row }">
      <InstructorLabel
        v-if="row.mInstructor"
        :mInstructor="row.mInstructor"
        position="is-left"
      />
      <span v-else-if="row.tEvent.instructorId"
        >id:({{ row.tEvent.instructorId }})</span
      >
      <span v-else>-</span>
    </b-table-column>
    <b-table-column width="9em" centered v-slot="{ row }">
      <CheckinStatusTag :tEvent="row.tEvent" /><small
        v-if="row.tEvent.hasCheckin"
        class="pl-2"
        >{{ row.tEvent.checkinAtLabel }}</small
      >
    </b-table-column>
    <b-table-column width="2em" centered v-slot="{ row }">
      <span v-if="row.tEvent.hasCheckin">
        <b-tooltip
          v-if="row.tEvent.hasCheckinComment"
          :label="row.tEvent.tCheckin.note"
          type="is-dark"
          position="is-left"
        >
          <b-icon icon="comment-outline" type="is-dark" />
        </b-tooltip>
      </span>
      <span v-else>
        <b-tooltip
          v-if="row.tEvent.note"
          :label="row.tEvent.note"
          type="is-dark"
          position="is-left"
        >
          <b-icon icon="comment-outline" type="is-dark" />
        </b-tooltip>
      </span>
    </b-table-column>
    <b-table-column centered width="2em" v-slot="{ row }">
      <b-tooltip
        v-if="row.mProxy"
        :label="`#${row.mProxy.code} ${row.mProxy.name}が代行しました`"
        type="is-warning"
      >
        <b-icon icon="bell-alert" type="is-warning" />
      </b-tooltip>
      <span v-else />
    </b-table-column>
    <b-table-column label="承認" width="3em" centered v-slot="{ row }">
      <b-tooltip
        label="支払い対象とする"
        type="is-dark"
        v-if="row.tEvent.tCheckin"
      >
        <b-checkbox
          class="ml-1"
          type="is-success"
          v-model="row.tEvent.tCheckin.confirmFlagChange"
          @input="willChangeStatus(row.tEvent.tCheckin)"
        />
      </b-tooltip>
    </b-table-column>
    <b-table-column label="管理" width="4em" v-slot="{ row }">
      <b-button
        type="is-warning"
        size="is-small"
        icon-left="square-edit-outline"
        @click="willManage(row.tEvent)"
      />
    </b-table-column>

    <section class="section" slot="empty">
      <div class="content has-text-grey has-text-centered">
        <template v-if="isLoading">
          <p>
            <b-icon icon="dots-horizontal" size="is-large" />
          </p>
          <p>Fetching data...</p>
        </template>
        <template v-else>
          <p>
            <b-icon icon="emoticon-sad" size="is-large" />
          </p>
          <p>Nothing's here&hellip;</p>
        </template>
      </div>
    </section>
  </b-table>
</template>

<script>
import CheckinStatusTag from './CheckinStatusTag.vue'
import ManageFormVue from './ManageForm.vue'
import { ApiCheckin } from '@/api'
import { EventTableFilter } from '@/types/typeEvent'
import { mapGetters } from 'vuex'
import CheckinProgramLabel from './CheckinProgramLabel.vue'

export default {
  components: { CheckinStatusTag, CheckinProgramLabel },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    tEvents: {
      type: Array,
      default: () => [],
    },
    tableFilter: {
      type: EventTableFilter,
      default: null
    },
  },
  data () {
    return {
      perPage: 10,
      defaultSort: 'startDateSortVal'
    }
  },
  computed: {
    ...mapGetters('storeCheckin', ['findMInstructor']),
    paginated () {
      return this.rows.length > this.perPage
    },
    structs () {
      return this.tEvents.map(tEvent => {
        return {
          tEvent,
          mInstructor: this.findMInstructor(tEvent.instructorId),
          mProxy: tEvent.isProxy ? this.findMInstructor(tEvent.tCheckin.proxyId) : null,
          facilitySortVal: tEvent.facility.label,
          startDateSortVal: tEvent.startDate.format('YYYYMMDDHHmm'),
        }
      })
    },
    rows () {
      if (!this.tableFilter) {
        return this.structs
      }
      return this.structs.filter(struct => this.tableFilter.test(struct.tEvent))
    },
  },
  methods: {
    willChangeStatus (tCheckin) {
      console.log(tCheckin, 'willChangeStatus')
      const bool = tCheckin.confirmFlagChange
      ApiCheckin.toggleConfirm([tCheckin.id], bool).then(() => {
        tCheckin.setConfirmed(bool)
      })
    },
    willManage (tEvent) {
      console.log(tEvent, 'willManage')
      this.$buefy.modal.open({
        parent: this,
        component: ManageFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { eventId: tEvent.id },
        events: {}
      })
    }
  }
}
</script>
