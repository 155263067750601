<template>
  <div>
    <section class="hero is-hero-bar is-main-hero">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item is-hero-content-item">
              <div style="padding: 0 2em">
                <h1 class="title is-spaced">実施確認</h1>
                <h3 class="subtitle" v-if="targetDate">
                  <b>{{ targetDate.format('YYYY/MM/DD (dd)') }}</b>
                </h3>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="buttons has-text-centered">
              <b-button icon-left="chevron-left"
              @click.prevent.stop="willChangeQueryDate(-1)"
              />
              <b-button icon-left="calendar" @click="willSelectQueryDate"
                >確認日選択</b-button
              >
              <b-button icon-right="chevron-right"
              @click.prevent.stop="willChangeQueryDate(1)"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <div class="columns is-multiline">
        <div class="column is-full-desktop is-three-quarters-fullhd">
          <SummaryTiles :tEvents="tEvents" :tableFilter="tableFilter" />
          <CardComponent
            v-if="targetDate"
            :title="`${targetDate.format('YYYY/MM/DD (dd)')}のレッスン一覧`"
            :has-button-slot="true"
            class="has-table"
          >
            <refresh-button
              slot="button"
              label="更新"
              :isLoading="isLoading"
              @button-click="willFetch"
            />
            <CardToolbar slot="toolbar">
              <div class="block" slot="left">
                <b-field label="" class="has-check">
                  <EnumPicker
                    noneLabel="全て"
                    :members="AREA_OPTIONS"
                    :grouped="true"
                    :grouped-multiline="false"
                    v-model="tableFilter.areaAlt"
                  />
                </b-field>
                <b-field label="" class="has-check" v-if="tableFilter.area">
                  <EnumPicker
                    noneLabel="全て"
                    :members="FACILITY_OPTIONS"
                    :grouped="true"
                    :grouped-multiline="false"
                    v-model="tableFilter.facility"
                    short
                  />
                </b-field>
                <b-field label="" class="has-check">
                  <EnumPicker
                    noneLabel="全て"
                    :members="STATUS_OPTIONS"
                    :grouped="true"
                    :grouped-multiline="false"
                    v-model="tableFilter.confirmStatus"
                  />
                </b-field>
              </div>
              <div class="block" slot="right">
                <div class="is-flex-direction-column">
                  <b-field label="">
                    <b-switch v-model="tableFilter.showAll"
                      >OFFスタッフ表示</b-switch
                    >
                  </b-field>
                  <b-field style="min-height: 40px; min-width: 250px">
                    <CheckinInstrcutorSearch
                      v-model="tableFilter.mInstructor"
                      icon="filter"
                    />
                  </b-field>
                </div>
              </div>
            </CardToolbar>
            <EventTable
              :isLoading="isLoading"
              :tableFilter="tableFilter"
              :tEvents="tEvents"
            />
          </CardComponent>
        </div>
        <div
          class="column is-full-desktop is-one-quarter-fullhd"
          v-if="latestEvents.length > 0"
        >
          <CardComponent title="最新" :has-button-slot="true">
            <refresh-button
              slot="button"
              label="更新"
              :isLoading="isLoading"
              @button-click="willFetch"
            />
            <div class="media-list">
              <b-loading :is-full-page="false" :active="isLoading" />
              <LatestMedia
                v-for="tEvent in latestEvents"
                :key="tEvent.id"
                :tEvent="tEvent"
              />
            </div>
          </CardComponent>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import CardToolbar from '@/components/CardToolbar.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { Enums } from '@/types/enum'
import SummaryTiles from '@/components/Dashboard/SummaryTiles.vue'
import EnumPicker from '@/components/EnumPicker.vue'
import EventTable from '@/components/Dashboard/EventTable.vue'
import LatestMedia from '@/components/Dashboard/LatestMedia.vue'
import RefreshButton from '@/components/RefreshButton.vue'
import QueryDateSelectFormVue from '@/components/Dashboard/QueryDateSelectForm.vue'
import CheckinInstrcutorSearch from '@/components/Dashboard/CheckinInstrcutorSearch.vue'
export default {
  mixins: [BaseMixin],
  components: {
    CardToolbar,
    CardComponent,
    SummaryTiles,
    EnumPicker,
    EventTable,
    LatestMedia,
    RefreshButton,
    CheckinInstrcutorSearch,
  },
  computed: {
    AREA_OPTIONS: () => Enums.Area.list,
    FACILITY_OPTIONS () {
      return this.tableFilter.area ? Enums.LessonFacility.list.filter(f => f.options.area === this.tableFilter.area.value) : []
    },
    STATUS_OPTIONS: () => Enums.CheckinConfirmStatus.list,
    ...mapState('storeCheckin', ['targetDate', 'tEvents']),
    ...mapState('storeCheckin', {
      tableFilter: 'dashboardTableFilter'
    }),
    ...mapGetters('storeCheckin', ['latestEvents'])
  },
  methods: {
    ...mapActions('storeCheckin', {
      forgeCheckinStates: 'forgeStates',
      forgeCheckinDatas: 'forgeDatas',
    }),
    willFetch () {
      this.isLoading = true
      setTimeout(() => {
        this.forgeCheckinDatas(null).then(() => {
          this.isLoading = false
        }).catch(this.handleError)
      }, 300)
    },
    willSelectQueryDate () {
      console.log('willSelectQueryDate')
      this.$buefy.modal.open({
        parent: this,
        component: QueryDateSelectFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { targetDate: this.targetDate },
        events: {}
      })
    },
    willChangeQueryDate (d) {
      this.isLoading = true
      this.forgeCheckinDatas(this.targetDate.add(d, 'day')).then(() => {
        this.isLoading = false
      }).catch(this.handleError)
    },
  },
  created () {
    this.isLoading = true
    this.forgeCheckinStates(true).catch(this.handleError)
  },
  mounted () {
    this.forgeCheckinDatas(null).then(() => {
      this.isLoading = false
    }).catch(this.handleError)
  },
}
</script>
