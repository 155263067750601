
import { ActionContext } from 'vuex' // eslint-disable-line no-unused-vars
import { ApiHoliday } from '@/api'
import { MHoliday } from '@/types/typeHoliday' // eslint-disable-line no-unused-vars
import { Enums } from '@/types/enum' // eslint-disable-line no-unused-vars

const StoreHolidayState = {
  dataState: false,
  /** @type {MHoliday[]} */
  mHolidays: [],
}

export default {
  namespaced: true,
  state: () => StoreHolidayState,
  getters: {
    mHolidayMap (state) {
      return state.mHolidays.reduce((map, row) => {
        return row.areas.map(areaVal => `${row.dateKey}:${areaVal}`).reduce((map, dateAreaKey) => {
          map[dateAreaKey] = row
          return map
        }, map)
      }, {})
    },
    findMHoliday: (state, getters) => (dateKey, areaVal) => {
      const dateAreaKey = `${dateKey}:${areaVal}`
      return getters.mHolidayMap[dateAreaKey] || null
    },
    /**
     * @param {StoreHolidayState} state
     * {areaVal: MHoliday[]}
     */
    mHolidayAreaMap (state) {
      const areaMap = {}
      Enums.Area.list.forEach(area => {
        areaMap[area.value] = []
      })
      return state.mHolidays.reduce((map, row) => {
        return row.areas.reduce((inmap, areaVal) => {
          inmap[areaVal].push(row)
          return inmap
        }, map)
      }, areaMap)
    },
    /**
     * @param {StoreHolidayState} state
     * @returns {MHoliday[]}
     */
    getAreaHolidays: (state, getters) => (areaVal) => {
      return areaVal ? getters.mHolidayAreaMap[areaVal] : []
    },
  },
  mutations: {
    fill (state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
      })
    },
    /**
     * @param {StoreHolidayState} state
     */
    resetDataState (state) {
      state.dataState = false
    },
  },
  actions: {
    /**
     * @param {ActionContext<StoreHolidayState>} { state, commit }
     */
    async forgeStates ({ state, commit }, force) {
      if (!force && state.dataState) {
        return
      }
      const { mHolidays } = await ApiHoliday.fetchHolidays()
      commit('fill', { mHolidays, dataState: true })
    },
  }
}
