<template>
  <b-tooltip type="is-info is-light" v-bind="$attrs" multilined>
    <span>{{ label }}</span>
    <template v-slot:content v-if="mInstructor">
      <span>ID:{{ mInstructor.id }}</span>
      &nbsp;<span>#{{ mInstructor.code }}</span
      ><br />
      <span>{{ mInstructor.name }}</span
      >&nbsp; <span>{{ mInstructor.kana }}</span
      ><br />
      <span>fee（{{ feeExistLabel }}）</span>
      <span v-if="!mInstructor.checkable"><br />実施確認OFF</span>
      <span v-if="mInstructor.note"><br />{{ mInstructor.note }}</span>
    </template>
  </b-tooltip>
</template>

<script>
import { MInstructor } from '@/types/typeInstructor'
export default {
  props: {
    mInstructor: MInstructor,
    labelProp: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    label () {
      return this.mInstructor ? this.mInstructor[this.labelProp] : ''
    },
    feeExistLabel () {
      if (!this.mInstructor) {
        return ''
      }
      if (this.mInstructor.feeCategoryExists.length === 0 && this.mInstructor.feeSpecialCount === 0) {
        return '-'
      }
      const labels = this.mInstructor.feeCategoryExists.map(t => t.label)
      if (this.mInstructor.feeSpecialCount > 0) {
        labels.push('特例')
      }
      return labels.join(',')
    }
  }
}
</script>

<style>
</style>