<template>
  <CardComponent :title="cardTitle" icon="lock" style="width: 400px">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
      <form @submit.prevent="handleSubmit(willSave)">
        <b-field label="パスワード" message="4文字以上必要です。">
          <ValidationInput
            type="password"
            v-model="userPasswordForm.password"
            rules="required|min:4|max:10"
            vid="base"
            :autofocus="true"
            password-reveal
            autocomplete="new-password"
          ></ValidationInput>
        </b-field>
        <b-field label="パスワード(確認)">
          <ValidationInput
            type="password"
            v-model="userPasswordForm.confirmation"
            rules="required|confirmed:base"
            password-reveal
            autocomplete="new-password"
          ></ValidationInput>
        </b-field>
        <hr />
        <b-field>
          <b-field grouped>
            <div class="control">
              <b-button
                native-type="submit"
                type="is-primary"
                :loading="isLoading"
                >変更</b-button
              >
            </div>
            <div class="control">
              <b-button
                type="is-primary is-outlined"
                @click="$emit('close')"
                :loading="isLoading"
                >キャンセル</b-button
              >
            </div>
          </b-field>
        </b-field>
      </form>
    </ValidationObserver>
  </CardComponent>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { ApiPassword } from '@/api'
import { UserPasswordForm } from '@/types/typeUser'
export default {
  mixins: [BaseMixin],
  props: {
    isShopPassword: Boolean,
  },
  components: { CardComponent },
  computed: {
    cardTitle () {
      return this.isShopPassword ? '店舗パスワード変更' : 'ユーザーパスワード変更'
    }
  },
  data () {
    return {
      userPasswordForm: new UserPasswordForm()
    }
  },
  methods: {
    willSave () {
      this.isLoading = true
      setTimeout(() => {
        ApiPassword.changePassword(this.userPasswordForm).then(() => {
          this.$emit('saved')
          this.$emit('close')
          this.isLoading = false
        }).catch(this.handleError)
      }, 500)
    },
  },
  created () {
    this.userPasswordForm.isShopPassword = this.isShopPassword
  }
}
</script>
