<template>
  <aside
    v-show="isAsideRightVisible || !isLayoutAsideHidden"
    class="aside is-placed-right"
  >
    <div ref="container" class="aside-container" @mouseenter="psUpdate">
      <aside-updates @data-updated="psInit" v-if="false" />

      <article class="media">
        <div class="media-content">
          <b-button type="is-info" outlined @click="clearChache"
            >キャッシュクリア</b-button
          >
        </div>
      </article>
    </div>
  </aside>
</template>

<script>
import { mapState } from 'vuex'
import PerfectScrollbar from 'perfect-scrollbar'
import AsideUpdates from '@/components/AsideUpdates'

export default {
  name: 'AsideRight',
  components: { AsideUpdates },
  computed: mapState([
    'isAsideRightVisible',
    'isLayoutAsideHidden'
  ]),
  methods: {
    psInit () {
      this.ps = new PerfectScrollbar(this.$refs.container)
    },
    psUpdate () {
      if (this.ps) {
        this.ps.update()
      }
    },
    clearChache () {
      // window.navigator.serviceWorker.getRegistrations()
      //   .then(registrations => {
      //     for (const registration of registrations) {
      //       registration.unregister()
      //     }
      //   })
      window.location.reload(true)
    }
  }
}
</script>
