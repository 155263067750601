import { DateFormat } from '@/config/const'
import { assign, Collection, parsePaymentYm } from '@/util'
import { Enums, TypeEnum } from './enum' // eslint-disable-line no-unused-vars
import { TCheckin } from './typeCheckin' // eslint-disable-line no-unused-vars
import { MInstructor } from './typeInstructor'
import { MProgram } from './typeProgram'
import { TPaymentWork } from './typeWork'

export class TPaymentStruct {
  constructor (data) {
    this.paymentYmKey = data.payment_ym
    this.paymentYm = parsePaymentYm(data.payment_ym)
    this.totalCount = Number(data.total) || 0
    this.confirmingCount = Number(data.confirming) || 0
    this.stagingCount = Number(data.staging) || 0
    this.tPaymentWork = data.work ? new TPaymentWork(data.work) : null
  }
  get ymLabel () {
    const { date } = this.paymentYm
    return date.format('YYYY年MM月')
  }
  get rangeLabel () {
    const { minDay, maxDay } = this.paymentYm
    return `${minDay.format('YYYY/MM/DD')}〜${maxDay.format('MM/DD')}`
  }

  get confirmedCount () {
    return this.totalCount - this.confirmingCount
  }

  get fixStatus () {
    return this.tPaymentWork ? this.tPaymentWork.fixStatus : Enums.PaymentWorkFixStatus.None
  }
}

export class TPaymentSoftArea extends TPaymentStruct {
  constructor (data) {
    super(data)
    this.area = Enums.Area.of(data.area)
  }
}

export class TSoftCheckin extends TCheckin {
  constructor (data) {
    super(data)
    this.area = Enums.Area.of(data.area)
    this.facility = Enums.LessonFacility.of(data.facility)

    this.programId = Number(data.program_id) || 0
    this.mProgram = data.program ? new MProgram(data.program) : null

    this.instructorId = Number(data.instructor_id) || 0
    this.mInstructor = data.instructor ? new MInstructor(data.instructor) : null

    this.minutes = Number(data.minutes) || 0

    // APIで都度判定
    this.category = data.category ? Enums.FeeCategory.of(data.category) : null // 特例の場合null
    // APIで都度判定
    this.unitAmount = (data.unit_amount === null) ? null : (Number(data.unit_amount) || 0)

    this.fixedAmount = (data.fixed_amount === null) ? null : (Number(data.fixed_amount) || 0)
    /** @type {TypeEnum[]} */
    this.feeInstills = (data.fee_instills || []).map(id => Enums.FeeInstill.of(id))
    this.paymentYm = parsePaymentYm(data.payment_ym)
  }

  get lessonStartAtSortVal () {
    return this.lessonStartAt.format('YYYYMMDDHHmm')
  }
  get hasUnitAmount () {
    return this.unitAmount !== null
  }
  get hasFixedAmount () {
    return this.fixedAmount !== null
  }
  get instillAmount () {
    return this.feeInstills.reduce((amount, instill) => {
      return amount + instill.options.amount
    }, 0)
  }
  get instillToolTip () {
    return this.feeInstills.reduce((label, instill) => {
      if (!label) {
        return instill.label
      }
      return label + '\n' + instill.label
    }, '')
  }

  get projectionKey () {
    return `${this.lessonStartAt.format(DateFormat)}${this.area.value}${this.facility.value}`
  }
}

export class TSoftCheckinProjectionStruct {
  static map = {}
  /** @param {TSoftCheckin[]} tSoftCheckins */
  static parse (tSoftCheckins) {
    return Object.values(tSoftCheckins.reduce((map, row) => {
      const key = row.projectionKey
      if (!(key in map)) {
        map[key] = new TSoftCheckinProjectionStruct(key, row.lessonStartAt, row.area, row.facility)
      }
      map[key].tSoftCheckins.push(row)
      return map
    }, {}))
      .map(struct => struct.syncAllConfirmFlag())
      .map(struct => struct.syncAllHasUnitAmount())
  }

  /**
   * @param {string} key
   * @param {dayjs.Dayjs} lessonDate
   * @param {TypeEnum} area
   * @param {TypeEnum} facility
   */
  constructor (key, lessonDate, area, facility) {
    this.key = key
    this.area = area
    this.lessonDate = lessonDate
    this.facility = facility
    /** @type {TSoftCheckin[]} */
    this.tSoftCheckins = []
    this.allHasUnitAmount = false
    this.allConfirmFlag = false
    this.detailOpened = false
  }

  get lessonDateSortVal () {
    return this.lessonDate.format('YYYYMMDDHHmm')
  }
  syncAllHasUnitAmount () {
    this.allHasUnitAmount = this.tSoftCheckins.every(row => row.hasUnitAmount)
    return this
  }
  syncAllConfirmFlag () {
    this.allConfirmFlag = this.tSoftCheckins.every(row => row.confirmFlag)
    return this
  }
  breakConfirmFlag () {
    this.allConfirmFlag = false
  }
  get checkinIds () {
    return this.tSoftCheckins.map(row => row.id)
  }
  get hasCheckins () {
    return this.tSoftCheckins.length > 0
  }
  setConfirmedAll (bool) {
    this.tSoftCheckins.forEach(row => row.setConfirmed(bool))
    this.allConfirmFlag = bool
  }
  replaceTSoftCheckin (tSoftCheckin) {
    this.tSoftCheckins = new Collection(this.tSoftCheckins).set(tSoftCheckin).members
  }
  removeTSoftCheckin (id) {
    this.tSoftCheckins = new Collection(this.tSoftCheckins).remove(id).members
    this.syncAllConfirmFlag()
    return this
  }
}

export class SoftCheckinSearch {
  constructor (paymentYmKey, type) {
    this.type = type // scope or area
    this.paymentYmKey = paymentYmKey
    /** @type {TypeEnum} */
    this.area = null
    this.mInstructor = null
    this.mProgram = null

    this.facilities = [] // filter用
  }

  copy () {
    const alt = new SoftCheckinSearch(this.paymentYmKeythis, this.type)
    assign(alt, this)
    return alt
  }

  get FACILITY_OPTIONS () {
    return this.area ? Enums.LessonFacility.list.filter(f => f.options.area === this.area.value) : []
  }

  setFacilityAll () {
    this.facilities = this.FACILITY_OPTIONS
  }

  get hasScopeCondition () {
    return this.mInstructor || this.mProgram
  }

  get params () {
    return {
      type_condition: this.type,
      payment_ym: this.paymentYmKey,
      instructor_id: this.mInstructor ? this.mInstructor.id : null,
      program_id: this.mProgram ? this.mProgram.id : null,
      area: this.area ? this.area.value : null,
    }
  }
}

export class SoftCheckinEditForm {
  /**
   * @param {TSoftCheckin} tSoftCheckin
   */
  constructor (tSoftCheckin) {
    this.id = tSoftCheckin.id
    this.feeInstills = tSoftCheckin.feeInstills
    this.minutes = tSoftCheckin.minutes
    this.fixedAmount = tSoftCheckin.hasFixedAmount ? tSoftCheckin.fixedAmount.toString() : ''
    this.note = tSoftCheckin.note
  }
  get params () {
    return {
      id: this.id,
      fee_instills: this.feeInstills.map(feeInstill => feeInstill.value),
      minutes: Number(this.minutes) || 0,
      fixed_amount: this.fixedAmount === '' ? null : (Number(this.fixedAmount) || 0),
      note: this.note || '',
    }
  }
}

export class PaymentWorkZipForm {
  constructor () {
    this.paymentYm = ''
    this.payCnt = ''
    this.payDate = ''
  }
  get params () {
    return {
      payment_ym: this.paymentYm,
      cnt: this.payCnt || '',
      date: this.payDate || '',
    }
  }
  reset () {
    this.paymentYm = ''
    this.payCnt = ''
    this.payDate = ''
  }
}