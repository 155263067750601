import { DateFormat } from '@/config/const'
import { parsePaymentYm } from '@/util'
import dayjs from 'dayjs'
import { Enums } from './enum'

export class TPaymentWork {
  constructor (data) {
    this.id = Number(data.id) || 0
    /** @type {string} */
    this.paymentYmKey = data.payment_ym
    this.paymentYm = parsePaymentYm(data.payment_ym)
    this.versionString = data.version
    this.versionAt = dayjs(data.version, 'YYYYMMDDHHmmss')
    this.fixStatus = Enums.PaymentWorkFixStatus.of(data.fix_status)
    this.instructorCount = Number(data.instructor_count) || 0
    this.bonusAmount = Number(data.bonus_amount) || 0
    this.amount = Number(data.amount) || 0
    this.totalAmount = Number(data.total_amount) || 0
  }
  get ymLabel () {
    const { date } = this.paymentYm
    return date.format('YYYY年MM月')
  }
  get rangeLabel () {
    const { minDay, maxDay } = this.paymentYm
    return `${minDay.format('YYYY/MM/DD')}〜${maxDay.format('MM/DD')}`
  }
}

export class TAbsSummary {
  constructor (data) {
    this.totalCount = Number(data.cnt) || 0
    this.baseAmount = Number(data.base_amount) || 0
    this.instillAmount = Number(data.instill_amount) || 0
    this.fixedAmount = Number(data.fixed_amount) || 0
    this.amount = Number(data.amount) || 0
    this.bonusAmount = Number(data.bonus_amount) || 0
    this.totalAmount = Number(data.total_amount) || 0
  }
}

export class TInstructorSummary extends TAbsSummary {
  constructor (data) {
    super(data)
    this.id = data.id
    this.code = data.code
    this.name = data.name
    this.hours = Number(data.hours) || 0
    this.targetDateCnt = Number(data.target_date_cnt) || 0
    this.withholdingAmount = Number(data.withholding_amount) || 0
    this.isBillingAnswerOk = data.billing_answer_ok
    this.billingConfirmAt = data.billing_confirm_at ? dayjs(data.billing_confirm_at, 'YYYYMMDDHHmmss') : null
  }
  get taxedAmount () {
    return this.totalAmount - this.withholdingAmount
  }

  get searchText () {
    return `${this.code}${this.name}`
  }
}
export class TAreaSummary extends TAbsSummary {
  constructor (data) {
    super(data)
    this.area = Enums.PaymentArea.of(data.area)
  }
}

export class TPaymentRow {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.areaName = data.area_name
    this.facility = Enums.LessonFacility.of(data.facility)
    this.programName = data.program_name
    this.targetDate = dayjs(data.target_date, DateFormat)
    this.minutes = Number(data.minutes) || 0
    this.instillMinutes = Number(data.instill_minutes) || 0
    this.categoryName = data.category_name // 特例含む
    this.unitAmount = Number(data.unit_amount) || 0
    this.baseAmount = Number(data.base_amount) || 0
    this.instillLabels = data.instill_labels
    this.instillAmount = Number(data.instill_amount) || 0
    this.fixedAmount = Number(data.fixed_amount) || 0
    this.amount = Number(data.amount) || 0
  }

  get receiptStructKey () {
    return this.targetDate.format(DateFormat)
  }
}