<template>
  <b-tooltip type="is-info is-light" v-bind="$attrs" multilined v-if="mProgram">
    <span>{{ label }}</span>
    <template v-slot:content v-if="mProgram">
      <span>ID:{{ mProgram.id }}</span
      >&nbsp;<span>fee: {{ mProgram.category.label }}</span
      ><br />
      <span>{{ mProgram.name }}</span>
      <br />分類:
      <span v-if="mParentGroup">{{ mParentGroup.name }}&nbsp;>&nbsp;</span>
      <span v-if="mPGroup">{{ mPGroup.name }}</span>
    </template>
  </b-tooltip>
  <span v-else />
</template>

<script>
import { MProgram, MProgramGroup } from '@/types/typeProgram'
export default {
  props: {
    mProgram: MProgram,
    mPGroup: MProgramGroup,
    mParentGroup: MProgramGroup,
    labelProp: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    label () {
      return this.mProgram ? this.mProgram[this.labelProp] : ''
    },
  }
}
</script>

<style>
</style>