import { Enums } from '@/types/enum'

export class AuthForm {
  constructor () {
    this.code = ''
    this.password = ''
  }
}
export class AuthUser {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.code = data.code || ''
    this.name = data.name || ''
    this.role = Enums.Role.of(data.role)
    this.area = data.area ? Enums.Area.of(data.area) : null
    this.note = data.note || ''
  }
}