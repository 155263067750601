<template>
  <ValidationField :rules="rules" grouped>
    <b-numberinput
      style="min-width: 80px; max-width: 200px"
      type="is-warning"
      min="5"
      max="300"
      step="5"
      :editable="false"
      v-model="valueAlt"
    ></b-numberinput>
    <p class="control" v-for="preset in presets" :key="preset">
      <b-button size="is-small" @click="setPreset(preset)"
        >{{ preset }}分</b-button
      >
    </p>
  </ValidationField>
</template>

<script>
export default {
  props: {
    value: Number,
    presets: Array,
    rules: String,
  },
  computed: {
    valueAlt: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
  },
  methods: {
    setPreset (val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style>
</style>