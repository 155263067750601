<template>
  <section>
    <p v-if="value">
      <b-icon :icon="icon" />
      <span class="ml-1">{{ value.optionLabel }}</span>
      <b-button
        class="ml-4"
        icon-left="close"
        size="is-small"
        @click="remove"
      />
    </p>
    <b-autocomplete
      v-else
      rounded
      v-model="inputText"
      :data="suggests"
      placeholder="検索"
      :icon="icon"
      clearable
      clearOnSelect
      openOnFocus
      field="optionLabel"
      @select="didSelect"
    >
      <template #empty>{{
        inputText ? '見つかりませんでした' : '氏名・かな・管理番号'
      }}</template>
    </b-autocomplete>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { MInstructor } from '@/types/typeInstructor'
export default {
  props: {
    value: MInstructor,
    exclude: {
      type: Number,
      default: 0
    },
    icon: String
  },
  data () {
    return {
      defaultId: this.value ? this.value.id : null,
      inputText: ''
    }
  },
  computed: {
    ...mapState('storeCheckin', ['mInstructors']),
    suggests () {
      if (!this.inputText) {
        return []
      }
      return this.mInstructors
        .filter(row => !this.exclude || row.id !== this.exclude)
        .filter(row => {
          if (!`${row.code}${row.kana}${row.name}`.includes(this.inputText)) {
            return false
          }
          if (this.defaultId && row.id === this.defaultId) {
            return true
          }
          return row.active
        })
    },
  },
  methods: {
    didSelect (row) {
      if (row) {
        this.$emit('input', row)
        this.inputText = ''
      }
    },
    remove () {
      this.$emit('input', null)
    }
  }
}
</script>
