<template>
  <CardComponent
    title="対象日選択"
    icon="calendar"
    headerIcon="close"
    @header-icon-click="$emit('close')"
  >
    <b-datepicker
      v-model="inputDate"
      icon="calendar-today"
      inline
      @input="didSelect"
    >
      <b-button
        label="Today"
        type="is-primary"
        outlined
        icon-left="calendar-today"
        @click="setToday" />
    </b-datepicker>
  </CardComponent>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { mapActions } from 'vuex'
import dayjs from 'dayjs'
export default {
  mixins: [BaseMixin],
  components: { CardComponent },
  props: {
    targetDate: dayjs.Dayjs
  },
  data () {
    return {
      inputDate: this.targetDate.toDate()
    }
  },
  methods: {
    ...mapActions('storeCheckin', ['forgeDatas']),
    didSelect (jsDate) {
      console.log(jsDate, 'didSelect')
      const newDate = dayjs(jsDate)
      this.forgeDatas(newDate)
      this.$emit('close')
    },
    setToday () {
      this.forgeDatas(dayjs())
      this.$emit('close')
    }
  },
}
</script>
