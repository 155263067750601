import { parsePaymentYm } from '@/util'
import { Enums } from './enum'
import { TPaymentRow } from './typeWork' // eslint-disable-line no-unused-vars

export class TBilling {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.paymentYmKey = data.payment_ym
    this.paymentYm = parsePaymentYm(data.payment_ym)
    this.minutes = Number(data.minutes) || 0
    this.amount = Number(data.amount) || 0
    this.taxRate = Number(data.tax_rate) || 0
    this.answer = Enums.BillingAnswer.of(data.answer)
    /** @type {string} */
    this.note = data.note || ''
  }

  get ymLabel () {
    const { date } = this.paymentYm
    return date.format('YYYY年MM月')
  }
  get rangeLabel () {
    const { minDay, maxDay } = this.paymentYm
    return `${minDay.format('YYYY/MM/DD')}〜${maxDay.format('MM/DD')}`
  }
  get timeLabel () {
    if (this.minutes < 60) {
      return `${this.minutes}分`
    }
    return `${(this.minutes / 60).toFixed(2)}時間`
  }
  get withholdingAmount () {
    const rate = (this.taxRate) / 10000
    return Math.floor(this.amount * rate)
  }
  get taxedAmount () {
    return this.amount - this.withholdingAmount
  }
}

export class BillingAnswerForm {
  /**
   * @param {TBilling} tBilling
   */
  constructor (tBilling) {
    this.paymentYmKey = tBilling.paymentYmKey
    this.confirmed = tBilling.answer.is('OK')
    this.note = tBilling.note
  }
  get params () {
    return {
      payment_ym: this.paymentYmKey,
      confirmed: this.confirmed,
      note: this.note || '',
    }
  }
}

export class ReceiptPaymentRowStruct {
  constructor (structKey, targetDate) {
    this.structKey = structKey
    this.targetDate = targetDate
    this.tPaymentRows = []
    this.sumAmount = 0
  }
  /** @param {TPaymentRow} tPaymentRow */
  addRow (tPaymentRow) {
    this.tPaymentRows.push(tPaymentRow)
    this.sumAmount += tPaymentRow.amount
    return this
  }
}