import { Enums } from '@/types/enum'

export const ManageMenuConfig = (isAdmin, isLimited) => {
  return [
    [
      {
        to: { name: 'Dashboard' },
        icon: 'check-bold',
        label: '実施確認'
      },
      {
        to: { name: 'Payment' },
        icon: 'currency-cny',
        label: '支払設定',
        hidden: isLimited,
      },
      ScheduleMenuConfig(isAdmin, isLimited),
      MasterMenuConfig(isAdmin, isLimited)
    ]
  ]
}

const MasterMenuConfig = (isAdmin, isLimited) => {
  return {
    label: 'マスタ管理',
    icon: 'settings',
    hidden: isLimited,
    menuSecondaryKey: 'Master',
    menuSecondaryIcon: 'settings',
    menuSecondaryLabel: 'マスタ管理',
    menuSecondary: [
      [
        {
          icon: 'alpha-p-box-outline',
          to: { name: 'Programs' },
          label: 'プログラム'
        },
        {
          icon: 'human-handsup',
          to: { name: 'Instructors' },
          label: 'インストラクター'
        },
        {
          icon: 'calendar-remove',
          to: { name: 'Holidays' },
          label: '祝日・休館日'
        }, {
          icon: 'lock',
          to: { name: 'Users' },
          label: 'アカウント',
          hidden: !isAdmin
        }
      ]
    ]
  }
}

const ScheduleMenuConfig = (isAdmin, isLimited) => {
  return {
    label: 'スケジュール設定',
    icon: 'calendar-blank',
    menuSecondaryKey: 'Schedule',
    menuSecondaryIcon: 'calendar-blank',
    menuSecondaryLabel: 'スケジュール設定',
    menuSecondary: [
      Enums.Area.list.map(area => {
        return {
          icon: 'alpha-a-box-outline',
          to: { name: 'Schedule', params: { pathArea: area.value } },
          label: area.label
        }
      }
      )
    ]
  }
}

export const ShopMenuConfig = () => {
  return [
    [
      {
        to: { name: 'Checkin' },
        icon: 'check-bold',
        label: '実施確認'
      },
    ]
  ]
}
export const InstructorMenuConfig = () => {
  return [
    [
      {
        to: { name: 'Billing' },
        icon: 'file-outline',
        label: '請求書確認'
      },
    ]
  ]
}