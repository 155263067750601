import axios from 'axios'
import { AuthUser, AuthForm } from '@/types/auth' // eslint-disable-line no-unused-vars
import { ConfirmServiceException, InValidServiceException, UnAuthoriedException } from '@/config/exception' // eslint-disable-line no-unused-vars
import { MUser, UserForm, UserPasswordForm } from './types/typeUser' // eslint-disable-line no-unused-vars
import { MInstructor, InstructorForm, TFee, FeeForm } from './types/typeInstructor' // eslint-disable-line no-unused-vars
import { MProgram, MProgramGroup, ProgramForm, ProgramGroupForm } from './types/typeProgram' // eslint-disable-line no-unused-vars
import { MHoliday } from './types/typeHoliday'
import { LessonForm, TLesson } from './types/typeLesson' // eslint-disable-line no-unused-vars
import { RepeatForm, TRepeat } from './types/typeRepeat' // eslint-disable-line no-unused-vars
import { CheckinForm, CheckinManageForm } from './types/typeCheckin' // eslint-disable-line no-unused-vars
import dayjs from 'dayjs'
import { DateFormat } from './config/const'
import { PaymentWorkZipForm, SoftCheckinEditForm, SoftCheckinSearch, TPaymentSoftArea, TPaymentStruct, TSoftCheckin } from './types/typePayment' // eslint-disable-line no-unused-vars
import { TAreaSummary, TInstructorSummary, TPaymentRow, TPaymentWork } from './types/typeWork' // eslint-disable-line no-unused-vars
import { BonusEditForm, TBonus, TPaymentBonus } from './types/typeBonus' // eslint-disable-line no-unused-vars
import { BillingAnswerForm, TBilling } from './types/typeBilling' // eslint-disable-line no-unused-vars
import { EventManageForm, TEvent } from './types/typeEvent' // eslint-disable-line no-unused-vars

export const ApiSocketUri = process.env.VUE_APP_WS_HOST ? `${process.env.VUE_APP_WS_HOST}/api/websocket` : `${location.origin.replace(/^http/, 'ws')}/api/websocket`

export const SocketParser = {
  UpdateEvent: (data) => {
    return {
      tEvent: new TEvent(data.event),
    }
  },
  ConfirmCheckin: (data) => {
    return {
      checkinIds: (data.checkin_ids || []).map(id => Number(id)),
      confirmed: !!Number(data.confirmed)
    }
  },
  DeleteEvent: (data) => {
    return {
      eventIds: (data.event_ids || []).map(id => Number(id)),
      lessonId: Number(data.lesson_id) || 0,
      repeatId: Number(data.repeat_id) || 0,
    }
  },
}

export const API = axios.create({
  baseURL: `${process.env.VUE_APP_API_HOST}/api`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
})

API.interceptors.response.use((response) => response, (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      throw new UnAuthoriedException()
    }
    if (error.response.status === 409) {
      const { message } = error.response.data
      throw new ConfirmServiceException(message)
    }
    if (error.response.status === 400) {
      const { message, data } = error.response.data
      throw new InValidServiceException(data, message)
    }
  }
  throw new Error('server error')
})

export const ApiAuth = {
  getAuth: async () => API.get('/admin/auth.json').then((r) => new AuthUser(r.data.auth)),
  /**
   * @param {AuthForm} form
   */
  login: async (form) => API.post('/admin/auth.json', { auth: form }).then((r) => new AuthUser(r.data.auth)),
  logout: async () => API.delete('/admin/auth.json'),
}

export const ApiUser = {
  fetchUsers: async () => API.get('/admin/user.json').then((r) => {
    return {
      /** @type {MUser[]} */
      mUsers: r.data.users.map(data => new MUser(data))
    }
  }),
  /**
   * @param {UserForm} form
   */
  saveUser: async (form) => API.post('/admin/user.json', { user: form.params }).then((r) => {
    return {
      mUser: new MUser(r.data.user)
    }
  }),
}
export const ApiPassword = {
  /**
   * @param {UserPasswordForm} form
   */
  changePassword: async (form) => API.post('/admin/password.json', form.params),
  /**
   * @param {number} userId
   */
  resetPassword: async (userId) => API.put('/admin/password.json', { user_id: userId })
}

export const ApiInstructor = {
  /**
   * @param {{}} params
   */
  fetchInstructors: async (params) => API.get('/admin/instructor.json', { params }).then((r) => {
    return {
      /** @type {MInstructor[]} */
      mInstructors: r.data.instructors.map(data => new MInstructor(data))
    }
  }),
  /**  @param {InstructorForm} form */
  saveInstructor: async (form) => API.post('/admin/instructor.json', { instructor: form.params }).then((r) => {
    return {
      mInstructor: new MInstructor(r.data.instructor)
    }
  }),
  /**  @param {number} id */
  deleteInstructor: async (id) => API.delete('/admin/instructor.json', { params: { id } }),
}

export const ApiFee = {
  /**  @param {number} user_id */
  fetchFees: async (userId) => API.get('/admin/fee.json', { params: { user_id: userId } }).then((r) => {
    return {
      /** @type {TFee[]} */
      tFees: r.data.fees.map(data => new TFee(data))
    }
  }),
  /**
   * @param {number} user_id
   * @param {FeeForm[]} forms
   */
  saveFees: async (userId, forms) => API.post('/admin/fee.json', { user_id: userId, fees: forms.map(form => form.params) }).then((r) => {
    return {
      mInstructor: new MInstructor(r.data.instructor)
    }
  }),
}

export const ApiProgram = {
  fetchPrograms: async () => API.get('/admin/program.json').then((r) => {
    return {
      /** @type {MProgram[]} */
      mPrograms: r.data.programs.map(data => new MProgram(data)),
      /** @type {MProgramGroup[]} */
      mProgramGroups: r.data.program_groups.map(data => new MProgramGroup(data))

    }
  }),
  /**
   * @param {ProgramForm} form
   */
  saveProgram: async (form) => API.post('/admin/program.json', { program: form.params }).then((r) => {
    return {
      mProgram: new MProgram(r.data.program),
    }
  }),
}

export const ApiProgramGroup = {
  /**
   * @param {ProgramGroupForm} form
   */
  saveProgramGroup: async (form) => API.post('/admin/program_group.json', { program_group: form.params }).then((r) => {
    return {
      mProgramGroup: new MProgramGroup(r.data.program_group),
    }
  }),
  /**
   * @param {number} id
   */
  deleteProgramGroup: async (id) => API.delete('/admin/program_group.json', { params: { id } }),
}

export const ApiHoliday = {
  fetchHolidays: async () => API.get('/admin/holiday.json').then((r) => {
    return {
      /** @type {MHoliday[]} */
      mHolidays: r.data.holidays.map(data => new MHoliday(data)),

    }
  }),
  /**
   * @param {HolidayForm} form
   */
  saveHoliday: async (form) => API.post('/admin/holiday.json', { holiday: form.params }).then((r) => {
    return {
      mHoliday: new MHoliday(r.data.holiday),
    }
  }),
  /**
   * @param {number} id
   */
  deleteHoliday: async (id) => API.delete('/admin/holiday.json', { params: { id } })
}

export const ApiLesson = {
  /**
   * @param {string} periodYm
   * @param {string} area
   */
  fetchWholeLessons: async (periodYm, area) => API.get('/admin/lesson.json', { params: { period_ym: periodYm, area, type: 'all' } }).then((r) => {
    return {
      /** @type {TLesson[]} */
      tLessons: r.data.lessons.map(data => new TLesson(data)),

    }
  }),
  /**
   * @param {string} periodYm
   * @param {string} area
   */
  fetchLessons: async (periodYm, area, type = 'spot') => API.get('/admin/lesson.json', { params: { period_ym: periodYm, area, type } }).then((r) => {
    return {
      /** @type {TLesson[]} */
      tLessons: r.data.lessons.map(data => new TLesson(data)),

    }
  }),
  /**
   * @param {string} periodYm
   * @param {string} area
   * @param {LessonForm} form
   * @param {boolean} confirmed
   */
  createLessons: async (periodYm, area, form, confirmed) => API.post('/admin/lesson.json', { period_ym: periodYm, area, lesson: form.params, confirmed }).then((r) => {
    return {
      /** @type {TLesson[]} */
      tLessons: r.data.lessons.map(data => new TLesson(data)),
    }
  }),
  /**
   * @param {LessonForm} form
   * @param {boolean} confirmed
   */
  editLesson: async (form, confirmed) => API.put('/admin/lesson.json', { lesson: form.params, confirmed }).then((r) => {
    return {
      tLesson: new TLesson(r.data.lesson),
    }
  }),
  /**
   * @param {number} id
   */
  deleteLesson: async (id) => API.delete('/admin/lesson.json', { params: { id } }),
  /**
   * @param {string} periodYm
   * @param {string} area
   */
  csvLessonsUri: (periodYm, area) => API.getUri({ url: `${API.defaults.baseURL}/admin/lesson.csv`, params: { period_ym: periodYm, area } })
}

export const ApiRepeat = {
  /**
   * @param {string} periodYm
   * @param {string} area
   */
  fetchRepeats: async (periodYm, area) => API.get('/admin/repeat.json', { params: { period_ym: periodYm, area } }).then((r) => {
    return {
      /** @type {TRepeat[]} */
      tRepeats: r.data.repeats.map(data => new TRepeat(data)),

    }
  }),
  /**
   * @param {string} periodYm
   * @param {string} area
   * @param {RepeatForm} form
   * @param {boolean} confirmed
   */
  saveRepeat: async (periodYm, area, form, confirmed) => API.post('/admin/repeat.json', { period_ym: periodYm, area, repeat: form.params, confirmed }).then((r) => {
    return {
      tRepeat: new TRepeat(r.data.repeat),
      /** @type {TLesson[]} */
      tLessons: r.data.lessons.map(data => new TLesson(data)),
    }
  }),
  /**
   * @param {number} id
   */
  deleteRepeat: async (id) => API.delete('/admin/repeat.json', { params: { id } }),
  /**
   * @param {string} periodYm
   * @param {string} area
   */
  csvRepeatsUri: (periodYm, area) => API.getUri({ url: `${API.defaults.baseURL}/admin/repeat.csv`, params: { period_ym: periodYm, area } })
}

export const ApiRepeatLesson = {
  /**
   * @param {number} repeatId
   */
  fetchRepeatLessons: async (repeatId) => API.get('/admin/repeat/lesson.json', { params: { repeat_id: repeatId } }).then((r) => {
    return {
      /** @type {TLesson[]} */
      tLessons: r.data.lessons.map(data => new TLesson(data)),

    }
  }),
}
export const ApiRepeatExoand = {
  /**
   * @param {string} periodYm
   * @param {string} area
   */
  loadRepeatExpand: async (periodYm, area) => API.get('/admin/repeat/expand.json', { params: { period_ym: periodYm, area } }).then((r) => {
    return {
      shouldExpand: !!r.data.should_expand,
      srcCount: Number(r.data.src_count) || 0,
      overrideCount: Number(r.data.override_count) || 0,
      /** @type {MHoliday[]} */
      mHolidays: (r.data.holidays || []).map(data => new MHoliday(data)),

    }
  }),
  /**
   * @param {string} periodYm
   * @param {string} area
   * @param {boolean} confirmed
   */
  expandRepeats: async (periodYm, area, confirmed) => API.post('/admin/repeat/expand.json', { period_ym: periodYm, area, confirmed }),
}

export const ApiCheckin = {
  fetchEvents: async (queryDateKey) => API.get('/checkin/event.json', { params: { query_date: queryDateKey || null } }).then((r) => {
    return {
      /** @type {dayjs.Dayjs]} */
      targetDate: dayjs(r.data.target_date, DateFormat),
      /** @type {TEvent[]} */
      tEvents: r.data.events.map(data => new TEvent(data)),
    }
  }),
  /**
   * @param {CheckinForm} form
   */
  createCheckin: async (form) => API.post('/checkin.json', { checkin: form.params }).then((r) => {
    return {
      tEvent: new TEvent(r.data.event),
    }
  }),
  /**
   * @param {number[]} ids
   * @param {boolean} confirmed
   */
  toggleConfirm: async (ids, confirmed) => API.put('/checkin.json', { checkin: { ids, confirmed } })
}

export const ApiCheckinManage = {
  /**
   * @param {EventManageForm} form
   */
  manageEvent: async (form) => API.put('/event/manage.json', { event: form.params }).then((r) => {
    return {
      tEvent: new TEvent(r.data.event),
    }
  }),
  /**
   * @param {number} eventId
   */
  forceCheckin: async (eventId) => API.post('/checkin/manage.json', { event_id: eventId }).then((r) => {
    return {
      tEvent: new TEvent(r.data.event),
    }
  }),
  /**
   * @param {number} eventId
   */
  revertCheckin: async (eventId) => API.delete('/checkin/manage.json', { params: { event_id: eventId } }),
  /**
   * @param {CheckinManageForm} form
   */
  manageCheckin: async (form) => API.put('/checkin/manage.json', { checkin: form.params }).then((r) => {
    return {
      tEvent: new TEvent(r.data.event),
    }
  }),
}

export const ApiPayment = {
  fetchPaymentStructs: async () => API.get('/payment.json').then((r) => {
    return {
      tPaymentStructs: r.data.structs.map(data => new TPaymentStruct(data)),
    }
  }),
}
export const ApiPaymentSoft = {
  /**
   * @param {string} paymentYm
   */
  fetchSoftAreas: async (paymentYm) => API.get('/payment/soft/area.json', { params: { payment_ym: paymentYm } }).then((r) => {
    return {
      tPaymentSoftAreas: r.data.areas.map(data => new TPaymentSoftArea(data)),
    }
  }),
  /**
   * @param {SoftCheckinSearch} search
   */
  fetchSoftCheckins: async (search) => API.get('/payment/soft/checkin.json', { params: search.params }).then((r) => {
    return {
      tSoftCheckins: r.data.checkins.map(data => new TSoftCheckin(data)),
    }
  }),
  /**
   * @param {SoftCheckinEditForm} form
   */
  editSoftCheckin: async (form) => API.put('/payment/soft/checkin.json', { checkin: form.params }).then((r) => {
    return {
      tSoftCheckin: new TSoftCheckin(r.data.checkin),
    }
  }),
  /**
   * @param {number} checkinId
   */
  deleteSoftCheckin: async (checkinId) => API.delete('/payment/soft/checkin.json', { params: { id: checkinId } })
}

export const ApiPaymentWork = {
  /**
   * @param {string} paymentYm
   */
  createPaymentWork: async (paymentYm) => API.post('/payment/work.json', { payment_ym: paymentYm }).then((r) => {
    return {
      tPaymentWork: new TPaymentWork(r.data.work),
    }
  }),
  fetchPaymentWorks: async () => API.get('/payment/work.json').then((r) => {
    return {
      tPaymentWorks: r.data.works.map(data => new TPaymentWork(data)),
    }
  }),
  /**
   * @param {string} paymentYm
   */
  readPaymentWork: async (paymentYm) => API.get('/payment/work.json', { params: { payment_ym: paymentYm } }).then((r) => {
    return {
      tPaymentWork: new TPaymentWork(r.data.work),
      /** @type {TInstructorSummary[]} */
      tInstructorSummarys: r.data.instructor_summary.map(data => new TInstructorSummary(data)),
      /** @type {TAreaSummary[]} */
      tAreaSummarys: r.data.area_sumary.map(data => new TAreaSummary(data)),
    }
  }),
  /**
   * @param {string} paymentYm
   */
  completePaymentWork: async (paymentYm) => API.put('/payment/work.json', { payment_ym: paymentYm }).then((r) => {
    return {
      tPaymentWork: new TPaymentWork(r.data.work),
    }
  }),
  /**
   * @param {PaymentWorkZipForm} paymentWorkZipForm
   */
  paymentWorkZipUri: (paymentWorkZipForm) => API.getUri({ url: `${API.defaults.baseURL}/payment/work.zip`, params: paymentWorkZipForm.params }),
  /**
   * @param {string} paymentYm
   */
  forgePaymentCsvUri: (paymentYm, type) => API.getUri({ url: `${API.defaults.baseURL}/payment.csv`, params: { payment_ym: paymentYm, type } }),
}

export const ApiPaymentInstructor = {
  /**
   * @param {string} paymentYm
   * @param {number} instructorId
   */
  readPaymentInstructor: async (paymentYm, instructorId = null) => API.get('/payment/instructor.json', { params: { payment_ym: paymentYm, instructor_id: instructorId } }).then((r) => {
    return {
      code: r.data.code || '',
      name: r.data.name || '',
      totalAmount: Number(r.data.total_amount) || 0,
      withholdingAmount: Number(r.data.withholding_amount) || 0,
      tPaymentRows: r.data.rows.map(d => new TPaymentRow(d)),
      tPaymentBonuses: r.data.bonuses.map(d => new TPaymentBonus(d))
    }
  }),
}
export const ApiBonus = {
  /**
   * @param {string} paymentYm
   */
  fetchBonuses: async (paymentYm) => API.get('/bonus.json', { params: { payment_ym: paymentYm } }).then((r) => {
    return {
      tBonuses: r.data.bonuses.map(d => new TBonus(d)),
    }
  }),
  /**
   * @param {BonusEditForm} bonusEditForm
   */
  saveBonus: async (bonusEditForm) => API.post('/bonus.json', { bonus: bonusEditForm.params }).then((r) => {
    return {
      tBonus: new TBonus(r.data.bonus),
    }
  }),
  /**
   * @param {number} id
   */
  deleteBonus: async (id) => API.delete('/bonus.json', { params: { id } })
}
export const ApiBilling = {
  /**
   * @param {string} paymentYm
   */
  fetchBillings: async (paymentYm) => API.get('/billing.json', { params: { payment_ym: paymentYm } }).then((r) => {
    return {
      tBillings: r.data.billings.map(d => new TBilling(d)),
    }
  }),
  /**
   * @param {BillingAnswerForm} billingAnswerForm
   */
  answerBilling: async (billingAnswerForm) => API.post('/billing.json', { billing: billingAnswerForm.params }).then((r) => {
    return {
      tBilling: new TBilling(r.data.billing),
    }
  }),
}