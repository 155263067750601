import { ActionContext } from 'vuex' // eslint-disable-line no-unused-vars
import { ApiCheckin, ApiCheckinManage, ApiInstructor, ApiProgram } from '@/api'
import { CheckinForm, CheckinManageForm } from '@/types/typeCheckin' // eslint-disable-line no-unused-vars
import { MInstructor } from '@/types/typeInstructor' // eslint-disable-line no-unused-vars
import { Collection } from '@/util'
import { DateFormat } from '@/config/const'
import { EventManageForm, EventTableFilter, TEvent } from '@/types/typeEvent' // eslint-disable-line no-unused-vars

const StoreCheckinState = {
  dataState: false,
  /** @type {dayjs.Dayjs]} */
  targetDate: null,
  dashboardTableFilter: new EventTableFilter(),
  /** @type {MProgram} */
  mPrograms: [],
  /** @type {MProgramGroup} */
  mProgramGroups: [],
  /** @type {MInstructor[]} */
  mInstructors: [],
  /** @type {TEvent[]} */
  tEvents: [],

}

export default {
  namespaced: true,
  state: () => StoreCheckinState,
  getters: {
    /**
     * @param {StoreCheckinState} state
     */
    latestEvents (state) {
      const hasCheckins = state.tEvents.filter(row => row.hasCheckin)
      return new Collection(hasCheckins).sort('checkinAtSortVal', 'desc').members.slice(0, 8)
    },
    /**
     * @param {StoreCheckinState} state
     */
    findEvent: (state) => (eventId) => {
      return state.tEvents.find(row => row.id === eventId)
    },
    /**
     * @param {StoreCheckinState} state
     */
    mPGroupMap (state) {
      return new Collection(state.mProgramGroups).forgeMap('id')
    },
    /**
     * @param {StoreCheckinState} state
     */
    findMPGroup: (state, getters) => (id) => {
      return id ? getters.mPGroupMap[id] : null
    },
    /**
     * @param {StoreCheckinState} state
     */
    mProgramMap (state) {
      return new Collection(state.mPrograms).forgeMap('id')
    },
    /**
     * @param {StoreCheckinState} state
     */
    findMProgram: (state, getters) => (id) => {
      return id ? getters.mProgramMap[id] : null
    },
    /**
     * @param {StoreCheckinState} state
     */
    mInstructorsMap (state) {
      return new Collection(state.mInstructors).forgeMap('id')
    },
    /**
     * @param {StoreCheckinState} state
     */
    findMInstructor: (state, getters) => (id) => {
      return id ? getters.mInstructorsMap[id] : null
    },
  },
  mutations: {
    fill (state, payload) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key]
      })
    },
    clearData (state) {
      state.dataState = false
      state.tEvents = []
    },
    /**
     * @param {StoreCheckinState} state
     * @param {{tEvents: TEvent[]}} {}
     */
    setTEvents (state, { tEvents }) {
      state.tEvents = new Collection(tEvents).sort('lessonId', 'asc').sort('startDateSortVal', 'asc').members
    },
    /**
     * @param {StoreCheckinState} state
     * @param {{tEvent: TEvent}} {}
     */
    syncEvent (state, { tEvent }) {
      tEvent.rowClass = 'is-updated'
      state.tEvents = new Collection(state.tEvents).setProp('rowClass', null).set(tEvent).sort('startDateSortVal', 'asc').members
    },
    /**
     * @param {StoreCheckinState} state
     * @param {{tEvent: TEvent}} {}
     */
    replaceEventIfExist (state, { tEvent }) {
      if (state.tEvents.some(row => row.id === tEvent.id)) {
        state.tEvents = state.tEvents.map(row => row.id === tEvent.id ? tEvent : row)
      }
    },
    /**
     * @param {StoreCheckinState} state
     * @param {{checkinIds: number[], confirmed: boolean}} {}
     */
    setConfirmedIfExist (state, { checkinIds, confirmed }) {
      const checkinIdSet = new Set(checkinIds)
      state.tEvents = state.tEvents.map(tEvent => {
        if (tEvent.tCheckin && checkinIdSet.has(tEvent.tCheckin.id)) {
          tEvent.tCheckin.setConfirmed(confirmed)
        }
        return tEvent
      })
    },
    /**
     * @param {StoreCheckinState} state
     * @param {{tEvent: TEvent}} {}
     */
    removeEventIfExist (state, { eventIds, lessonId, repeatId }) {
      const eventIdSet = new Set(eventIds)
      state.tEvents = state.tEvents.filter(tEvent => {
        if (eventIdSet.has(tEvent.id)) {
          return false
        }
        if (lessonId && lessonId === tEvent.lessonId) {
          return false
        }
        if (repeatId && repeatId === tEvent.repeatId) {
          return false
        }
        return true
      })
    },
  },
  actions: {
    /**
     * @param {ActionContext<StoreCheckinState>} { state, commit }
     */
    async forgeStates ({ state, getters, commit }, force) {
      if (!force && state.dataState) {
        return
      }
      const [{ mInstructors }, { mPrograms, mProgramGroups }] = await Promise.all([
        ApiInstructor.fetchInstructors(),
        ApiProgram.fetchPrograms(),
      ])
      commit('fill', { mInstructors, mPrograms, mProgramGroups, dataState: true })
    },
    /**
     * @param {ActionContext<StoreCheckinState>} { state, commit }
     */
    async forgeDatas ({ state, commit }, selectedDate) {
      const someDate = selectedDate || state.targetDate
      const dateKey = someDate ? someDate.format(DateFormat) : null
      const { targetDate, tEvents } = await ApiCheckin.fetchEvents(dateKey)
      commit('setTEvents', { tEvents })
      commit('fill', { targetDate })
    },
    /**
     * @param {ActionContext<StoreCheckinState>} { state, commit }
     * @param {CheckinForm} form
     */
    async createCheckin ({ state, commit }, form) {
      console.log('createCheckin')
      const { tEvent } = await ApiCheckin.createCheckin(form)
      commit('syncEvent', { tEvent })
    },
    /**
     * @param {ActionContext<StoreCheckinState>} { state, commit }
     * @param {EventManageForm} form
     */
    async manageEvent ({ state, commit }, form) {
      console.log(form, 'manageEvent')
      const { tEvent } = await ApiCheckinManage.manageEvent(form)
      commit('syncEvent', { tEvent })
    },
    /**
     * @param {ActionContext<StoreCheckinState>} { state, commit }
     * @param {{eventId: number}} {}
     */
    async forceCheckin ({ state, commit }, { eventId }) {
      console.log('forceCheckin')
      const { tEvent } = await ApiCheckinManage.forceCheckin(eventId)
      commit('syncEvent', { tEvent })
    },
    /**
     * @param {ActionContext<StoreCheckinState>} { state, commit }
     * @param {{tEvent: TEvent}} {}
     */
    async revertCheckin ({ state, commit }, { tEvent }) {
      console.log('revertCheckin')
      await ApiCheckinManage.revertCheckin(tEvent.id)
      tEvent.revertCheckin()
      commit('syncEvent', { tEvent })
    },
    /**
     * @param {ActionContext<StoreCheckinState>} { state, commit }
     * @param {CheckinManageForm} form
     */
    async manageCheckin ({ state, commit }, form) {
      console.log(form, 'manageCheckin')
      const { tEvent } = await ApiCheckinManage.manageCheckin(form)
      commit('syncEvent', { tEvent })
    },
  }
}