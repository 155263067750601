
import { assign } from '@/util'
import { Enums } from './enum'

export class MProgram {
  constructor (data) {
    this.id = Number(data.id) || 0
    /** @type {string} */
    this.name = data.name || ''
    this.category = Enums.FeeCategory.of(data.category)
    this.parentGroupId = data.parent_group_id ? Number(data.parent_group_id) : null
    this.groupId = data.group_id ? Number(data.group_id) : null
    this.minutes = data.minutes ? Number(data.minutes) : null
    /** @type {string} */
    this.note = data.note || ''
    /** @type {boolean} */
    this.active = data.active
    /** @type {string[]} */
    this.areas = data.areas || []
  }
}

export class ProgramForm {
  constructor () {
    this.id = null
    this.category = null
    this.parentGroupId = null
    this.groupId = null
    this.name = ''
    this.minutes = null
    this.areas = []
    this.note = ''
    this.active = true
  }

  get parentGroupIdAlt () {
    return this.parentGroupId
  }
  set parentGroupIdAlt (val) {
    this.parentGroupId = val
    this.groupId = null
  }
  /**
   * @param {MProgram} mProgram
   */
  fill (mProgram) {
    assign(this, mProgram)
    return this
  }
  get params () {
    return {
      id: this.id,
      category: this.category.value,
      parent_group_id: Number(this.parentGroupId) || null,
      group_id: Number(this.groupId) || null,
      name: this.name,
      minutes: Number(this.minutes) || null,
      note: this.note,
      areas: this.areas,
      active: this.active,
    }
  }
}

export class ProgramFilter {
  constructor () {
    this.text = ''
    this.showActive = true
    this.showDeActive = false
    /** @type {string} */
    this.areaVal = null
    /** @type {number} */
    this.parentGroupId = null
    /** @type {number} */
    this.groupId = null
  }

  get parentGroupIdAlt () {
    return this.parentGroupId
  }
  set parentGroupIdAlt (val) {
    this.parentGroupId = val
    this.groupId = null
  }

  /**
   * @param {MProgram} mProgram
   */
  test (mProgram) {
    if (!this.showActive && mProgram.active) {
      return false
    }
    if (!this.showDeActive && !mProgram.active) {
      return false
    }
    if (this.areaVal && !mProgram.areas.includes(this.areaVal)) {
      return false
    }
    if (this.parentGroupId && mProgram.parentGroupId !== this.parentGroupId) {
      return false
    }
    if (this.groupId && mProgram.groupId !== this.groupId) {
      return false
    }
    const search = `${mProgram.name}${mProgram.note}`
    if (this.text && !search.includes(this.text)) {
      return false
    }
    return true
  }
}

export class MProgramGroup {
  constructor (data) {
    this.id = Number(data.id) || 0
    /** @type {string} */
    this.name = data.name || ''
    this.parentId = data.parent_id ? Number(data.parent_id) : null
    this.poolFlag = data.pool_flag
  }
}

export class ProgramGroupForm {
  constructor () {
    this.id = 0
    /** @type {number} */
    this.parentId = null
    this.name = ''
    this.poolFlag = false
  }
  /**
   * @param {MProgramGroup} mProgramGroup
   */
  fill (mProgramGroup) {
    assign(this, mProgramGroup)
    return this
  }
  get params () {
    return {
      id: this.id,
      parent_id: this.parentId,
      name: this.name,
      pool_flag: this.poolFlag,
    }
  }
}