import { DateFormat } from '@/config/const'
import { Enums, TypeEnum } from '@/types/enum' // eslint-disable-line no-unused-vars
import { assign } from '@/util'
import dayjs from 'dayjs'

export class MHoliday {
  constructor (data) {
    this.id = Number(data.id) || 0
    this.name = data.name
    this.targetDate = dayjs(data.target_date, DateFormat)
    this.year = this.targetDate.format('YYYY')
    /** @type {string[]} */
    this.areas = data.areas || []
    this.officialFlag = data.official_flag
  }

  get dateKey () {
    return this.targetDate.format(DateFormat)
  }

  get targetDateSortVal () {
    return this.dateKey
  }
  hasArea (areaVal) {
    return this.areas.includes(areaVal)
  }
  get typeLabel () {
    return this.officialFlag ? '祝日' : '休館日'
  }
}

export class HolidayForm {
  constructor () {
    this.id = null
    this.name = ''
    this.areas = []
    this.targetDate = null
    this.officialFlag = false
  }
  /**
   * @param {MHoliday} mHoliday
   */
  fill (mHoliday) {
    assign(this, mHoliday)
  }
  get params () {
    return {
      id: this.id,
      name: this.name,
      areas: this.areas,
      target_date: this.targetDate.format(DateFormat),
      official_flag: this.officialFlag
    }
  }
}
export class HolidayTableFilter {
  constructor () {
    /** @type {TypeEnum} */
    this.area = null
    this.year = null
    this.showOfficial = true
    this.showSome = true
  }

  /**
   * @param {MHoliday} mHoliday
   */
  test (mHoliday) {
    if (this.area && !mHoliday.areas.includes(this.area.value)) {
      return false
    }
    if (this.year && mHoliday.year !== this.year) {
      return false
    }
    if (!this.showOfficial && mHoliday.officialFlag) {
      return false
    }
    if (!this.showSome && !mHoliday.officialFlag) {
      return false
    }
    return true
  }
}