<template>
  <nav
    v-show="isNavBarVisible"
    id="navbar-main"
    class="navbar"
    :class="navBarClass"
  >
    <div class="navbar-brand no-negative-margin-left">
      <a
        @click.prevent="menuToggle"
        :title="toggleTooltip"
        class="navbar-item is-desktop-icon-only"
      >
        <b-icon :icon="menuToggleIcon" />
      </a>
    </div>
    <div v-if="isLayoutMobile" class="navbar-brand is-right">
      <span class="navbar-item">
        <b-icon icon="tag" custom-size="default" />
        <span v-if="authUser">{{ authUser.name }}</span>
      </span>
      <a
        class="navbar-item navbar-item-menu-toggle"
        @click.prevent="menuNavBarToggle"
      >
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{
        'is-active': isMenuNavBarActive,
        'no-negative-margin-right': isLayoutBoxed,
      }"
    >
      <div class="navbar-end">
        <NavBarMenu class="has-divider has-user-avatar">
          <div class="navbar-item">
            <b-icon icon="account" custom-size="default" />
            <span v-if="authUser">{{ authUser.name }}</span>
          </div>

          <div slot="dropdown" class="navbar-dropdown">
            <span class="navbar-item">
              <b-icon icon="tag" custom-size="default" />
              <span v-if="authUser">{{ authUser.code }}</span>
            </span>
            <span class="navbar-item">
              <b-icon
                icon="checkbox-marked-circle-outline"
                custom-size="default"
              />
              <span v-if="authUser">{{ authUser.role.label }}権限</span>
            </span>
            <hr class="navbar-divider" />
            <a class="navbar-item" @click.prevent="clearChache">
              <b-icon icon="refresh" custom-size="default" />
              <span>キャッシュクリア</span>
            </a>
            <a
              v-if="!isShopRole"
              class="navbar-item"
              @click.prevent="willChangePassword"
            >
              <b-icon icon="key-variant" custom-size="default" />
              <span>パスワード変更</span>
            </a>
          </div>
        </NavBarMenu>
        <a
          class="navbar-item is-desktop-icon-only"
          title="ログアウト"
          @click="willLogout"
        >
          <b-icon icon="logout" custom-size="default" />
          <span>ログアウト</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import BaseMixin from '@/baseMixin'
import NavBarMenu from '@/components/NavBarMenu.vue'
import PasswordFormVue from '@/components/App/PasswordForm.vue'

export default {
  mixins: [BaseMixin],
  components: { NavBarMenu },
  data () {
    return {
      isMenuNavBarActive: false
    }
  },
  computed: {
    ...mapGetters('storeAuth', ['isManageRole', 'isShopRole']),
    menuNavBarToggleIcon () {
      return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
    },
    menuToggleIcon () {
      if (this.isLayoutAsideHidden) {
        return this.isAsideVisible ? 'close' : 'menu'
      }

      const isExpanded = this.isLayoutMobile ? this.isAsideMobileExpanded : this.isAsideExpanded

      return isExpanded ? 'backburger' : 'forwardburger'
    },
    toggleTooltip () {
      return this.isAsideExpanded ? 'Collapse' : 'Expand'
    },
    navBarClass () {
      let classAddon = ''

      if (!this.isLayoutBoxed) {
        classAddon += 'is-fixed-top '
      }

      if (this.navBarColor) {
        classAddon += this.navBarColor + ' '
      }

      return classAddon
    },
    ...mapState([
      'isLayoutMobile',
      'isLayoutAsideHidden',
      'isLayoutBoxed',
      'isNavBarVisible',
      'navBarColor',
      'isAsideVisible',
      'isAsideExpanded',
      'isAsideMobileExpanded',
      'isAsideRightVisible',
      'isAsideRightActive',
      'hasUpdates'
    ]),
    ...mapState('storeAuth', ['authUser'])
  },
  mounted () {
    this.$router.afterEach(() => {
      this.isMenuNavBarActive = false
    })
  },
  methods: {
    ...mapActions('storeAuth', ['logout']),
    menuToggle () {
      if (this.isLayoutMobile) {
        this.$store.commit('asideMobileStateToggle')
      } else if (this.isLayoutAsideHidden) {
        this.$store.dispatch('asideVisibilityToggle')
      } else {
        this.$store.commit('asideStateToggle')
      }
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = (!this.isMenuNavBarActive)
    },
    updatesToggle () {
      this.$store.dispatch('asideRightToggle')
    },
    willLogout () {
      this.isLoading = true
      this.$router.replace({ name: 'Login' })
      this.logout().then(() => {
        this.$buefy.snackbar.open({
          message: 'ログアウトしました',
          queue: false,
          type: 'is-danger'
        })
      }).catch(this.handleError)
    },
    willChangePassword () {
      console.log('willChangePassword')
      this.$buefy.modal.open({
        parent: this,
        component: PasswordFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        events: {
          saved: () => {
            this.alertSuccess('パスワードを更新しました')
          },
        }
      })
    },
    clearChache () {
      // window.navigator.serviceWorker.getRegistrations()
      //   .then(registrations => {
      //     for (const registration of registrations) {
      //       registration.unregister()
      //     }
      //   })
      window.location.reload(true)
    }
  }
}
</script>
